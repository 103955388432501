import React from "react";
import {CandidateType, CandidateTypeRestControllerApiFactory} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";


export const CandidateTypeEditPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(CandidateTypeRestControllerApiFactory);
    


    const form = useForm({
        fetcher: (id) => ctrl.getCandidateType(id),
        updater: (bean) => ctrl.saveCandidateType(bean),
        remover: (id) => ctrl.deleteOrDismissCandidateType(id),
        factory: () => {
            return {} as CandidateType;
        },

    } as UseFormProps<CandidateType>);

    return (
        <>
            <FormContent form={form} title={"Formulario de Tipo de Candidato"}>


                <FormInput type={"text"} field={"name"} label={"Nombre"}/>

                <FormInput type={"text"} field={"description"}
                           label={"Descripción"} rows={10}/>

            </FormContent>
        </>
    );
};