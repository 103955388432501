import {SkillLevel, SkillLevelRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {COLOR_GRIS} from "../../../../components/layout/colors";
import {Grid} from "@mui/material";
import {useApiManager} from "../../../../components/utils/api";

export const SkillLevelListPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(SkillLevelRestControllerApiFactory);

    const listConfig: AppListConfig<SkillLevel> = {
        title: "Listado de niveles de habilidad ",
        onClick: "/app/admin/recruitment/skilllevel",
        decorator: (bean: SkillLevel) => (
            <Grid container style={{ color: bean.dismissed ? COLOR_GRIS : "" }}>
                <Grid item sm>
                    {bean.label}
                </Grid>
                <Grid item sm>
                    {bean.level}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            
            return ctrl.pageSkillLevel(currentPage, size, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};