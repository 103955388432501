import {useForm, UseFormProps} from "../../../form/form_model";
import {useApiManager} from "../../../utils/api";
import {
    Applicant,
    ApplicantRestControllerApiFactory,
    Candidate,
    CandidateRestControllerApiFactory,
    Recruitment,
    RecruitmentRestControllerApiFactory,
} from "arteco-api-client-ts";
import React, {useEffect, useState} from "react";
import {FormContent} from "../../../form/form_inputs/form_buttons";
import FormSelect from "../../../form/form_inputs/form_select";
import {types} from "../../../form/types";
import {FormInput} from "../../../form/form_inputs/form_input";
import FormAutocomplete from "../../../form/form_inputs/form_autocomplete";
import {useParams} from "react-router-dom";
import {Box, Button, FormControl, Grid, Link, MenuItem, Modal, Select, Typography} from "@mui/material";
import {dedicationModalStyle} from "../../../../pages/employee/modal";
import {parsePathId} from "../../../form/form_utils";

export const ApplicationEdit = () => {
    const params = useParams();
    const recruitmentId = parsePathId(params, "recruitmentId", true);
    const candidateId = parsePathId(params, "candidateId", true);
    const apiManager = useApiManager();

    const applicantApi = apiManager.factory(ApplicantRestControllerApiFactory);
    const recruitmentApi = apiManager.factory(RecruitmentRestControllerApiFactory);
    const candidateApi = apiManager.factory(CandidateRestControllerApiFactory);

    const [recruitments, setRecruitments] = useState<Recruitment[]>([]);
    const [recruitment, setRecruitment] = useState<Recruitment>();
    const [candidate, setCandidate] = useState<Candidate>();
    const [showTransferModal, setShowTransferModal] = useState<boolean>(false);
    const [transferRecruitmentId, setTransferRecruitmentId] = useState<number|undefined>(undefined);
    const [copiedApplicationId, setCopiedApplicationId] = useState<number|undefined>(undefined);

    useEffect(() => {
        apiManager
            .execute(recruitmentApi.listRecruitment(true, undefined, undefined))
            .then(recruitments => {
                setRecruitments(recruitments);
            });
    }, []);

    const loadCandidate = (candId? : number) => {
        const cid = candId || candidateId;
        if (cid) {
            apiManager
                .execute(candidateApi.getCandidate(cid))
                .then((candidate) => {
                    setCandidate(candidate)
                });
        }
    }

    useEffect(()=>{
        loadCandidate();
    }, [candidateId]);

    useEffect(()=>{
        if (recruitmentId) {
            apiManager
                .execute(recruitmentApi.getRecruitment(recruitmentId))
                .then((recruitment) => {
                    setRecruitment(recruitment);
                });
        }
    }, [recruitmentId]);


    const form = useForm<Applicant>({
        fetcher: (id) => applicantApi.getApplicant(id),
        postLoad: (bean) => loadCandidate(bean.candidate?.id),
        updater: (bean) => applicantApi.saveApplicant(bean),
        remover: (id) => applicantApi.deleteOrDismissApplicant(id),
        factory: () => {
            return {
                recruitment: recruitment,
                candidate: candidate,
            } as Applicant;
        },
        extraActions: [
            {
                label: "Transferir",
                action: () => {
                    setShowTransferModal(true);
                },
            },
        ]
    } as UseFormProps<Applicant>);

    return (
        <>
            <FormContent form={form} title={"Ficha de la candidatura"}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <FormAutocomplete value={recruitment} field={"recruitment"} label={"Proceso"}
                                          type={types.recruitment}/>
                    </Grid>
                    <Grid item xs>
                        <Link variant={"button"}
                              href={"/app/admin/recruitment/recruitment/" + form.propModel.value?.recruitment?.id}>
                            <span>Ir al Proceso</span>
                        </Link>
                    </Grid>
                </Grid>
                <Grid container spacing={3} sx={{mb: 2}}>
                    <Grid item xs={6}>
                        <FormAutocomplete value={candidate} field={"candidate"} label={"Candidato"}
                                          type={types.candidates}/>
                    </Grid>
                    <Grid item xs>
                        <Link variant={"button"}
                              href={"/app/admin/recruitment/candidate/" + form.propModel.value?.candidate?.id}>
                            <span>Ir al candidato</span>
                        </Link>
                        &nbsp; <br/>
                        <span style={{fontStyle: "italic"}}>
                        {candidate?.email} | {candidate?.telephone}</span>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={6}><FormSelect type={types.applicantStatus} field={"state"} label={"Estado"}/></Grid>
                    <Grid item xs={6}><FormInput type={"datetime"} field={"nextEvent"}
                                                 label={"Próximo evento planificado"}/></Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={6}><FormInput type={"number"} field={"rating"}
                                                 label={"Valoración de Candidatura [1-5]"}/></Grid>
                    <Grid item xs={6}><FormInput type={"number"} field={"reqAnnualPayroll"}
                                                 label={"Salario a proponer"}/></Grid>
                </Grid>


                <FormInput field={"comment"} label={"Comentario"} rows={20}/>
            </FormContent>
            <Modal
                open={showTransferModal}
                onClose={_ => setShowTransferModal(false)}
                aria-labelledby="transfer-modal-title"
                aria-describedby="transfer-modal-description">
                <Box sx={dedicationModalStyle}>
                    <Typography id="transfer-modal-description" sx={{mt: 2}}>
                        <strong>Transferir candidato</strong><br/><br/>
                        <Grid container spacing={1} sx={{mb:1}}>
                            <Grid item xs={9}>
                                <FormControl fullWidth>
                                    <Select
                                        variant={"standard"}
                                        placeholder={"Selecciona el proceso a transferir candidato"}
                                        id="transfer-select"
                                        label="Proceso">
                                        {recruitments && recruitments.map(r => (
                                            <MenuItem onClick={_ => setTransferRecruitmentId(r.id)} key={"t-r-" + r.id} value={r.id}>{r.title}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant={"outlined"}
                                        onClick={() => {
                                            if (transferRecruitmentId && form.propModel.value?.id) {
                                                apiManager
                                                    .execute(applicantApi.copyApplicant(form.propModel.value?.id, transferRecruitmentId))
                                                    .then(id => {
                                                        setCopiedApplicationId(id);
                                                    });
                                            }else{
                                                alert("Selecciona un proceso");
                                            }
                                        }}>
                                    Asignar
                                </Button>
                            </Grid>
                        </Grid>

                        {copiedApplicationId && (
                            <Link href={"/app/admin/recruitment/candidate/undefined/application/"+copiedApplicationId}>
                                Ir a candidatura copiada
                            </Link>
                        )}

                    </Typography>
                </Box>
            </Modal>
        </>
    );
};