import React from "react";
import {TrainingLevel, TrainingLevelRestControllerApiFactory,} from "arteco-api-client-ts";

import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Grid} from "@mui/material";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";

export const TrainingLevelEditPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(TrainingLevelRestControllerApiFactory);

    const form = useForm({
        fetcher: (id) => ctrl.getTrainingLevel(id),
        updater: (bean) => ctrl.saveTrainingLevel(bean),
        remover: (id) => ctrl.deleteOrDismissTrainingLevel(id),
        factory: () => {
            return {
            } as TrainingLevel;
        },
    } as UseFormProps<TrainingLevel>);

    return (
        <>
            <FormContent form={form} title={"Formulario de Nivel de Formación"}>
                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput field={"level"} type={"number"} label={"Nivel"} />
                    </Grid>
                    <Grid item sm>
                        <FormInput type={"text"} field={"label"} label={"Etiqueta"} />
                    </Grid>
                </Grid>
                <FormInput field={"description"} type={"text"} label={"Descripción"} rows={2} />
            </FormContent>
        </>
    );
};