import {PayrollPack, PayrollPackRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {Grid} from "@mui/material";
import {dateFromToString} from "../../../../components/utils/dates";
import {useApiManager} from "../../../../components/utils/api";
import {CustomNumberFormat} from "../../../../components/utils/custom_number_format";

export const PayrollPackListPage = () => {
    const apiManager = useApiManager();

    const listConfig: AppListConfig<PayrollPack> = {
        title: "Pack de Nóminas ",
        onClick: "/app/admin/employee/payrollpack",
        decorator: (bean: PayrollPack) => (
            <Grid container>
                <Grid item sm>
                    {dateFromToString(bean.periodFrom)}
                </Grid>
                <Grid item sm>
                    {dateFromToString(bean.periodTo)}
                </Grid>
                <Grid item sm>
                    {bean.type}
                </Grid>
                <Grid item sm>
                    <CustomNumberFormat amount={bean.companyCost}/>
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            
            const ctrl = apiManager.factory(PayrollPackRestControllerApiFactory);
            return ctrl.pagePayrollPack(currentPage, size, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};