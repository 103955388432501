import {useApiManager} from "../../../../components/utils/api";

import React, {useEffect, useState} from "react";

import {Box, List, ListItem, Tab, TextField} from "@mui/material";
import AdminInner from "../../../../components/layout/admin_inner";
import "./occupation.module.scss";
import {TabContext, TabList, TabPanel} from "@mui/lab";

import {OccupationGroup} from "./occupation_group";
import {OccupationIndividual} from "./occupation_individual";
import {useSearchParams} from "react-router-dom";
import {Occupation, OccupationRestControllerApiFactory} from "arteco-api-client-ts";


export const OccupationPage = () => {
	const apiManager = useApiManager();
	const [searchParams, setSearchParams] = useSearchParams();

	const occupationCtrl = apiManager.factory(OccupationRestControllerApiFactory);

	const [selectedMainTab, setSelectedMainTab] = useState<string>("group");
	const [occupation, setOccupation] = useState<Occupation | undefined>();
	const [selectedMonth, setSelectedMonth] = useState<string>((new Date().getMonth() + 1).toString());
	const [year, setYear] = useState<number>(parseInt(searchParams.get("year") || "0") || new Date().getFullYear());

	const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

	useEffect(() => {
		apiManager.execute(occupationCtrl.occupation(undefined, year))
		.then(occupation => setOccupation(occupation));
	}, [year]);


	const changeMonth = (month: string): void => {
		setSelectedMonth(month);
	}


	return (
		<>
			<AdminInner title={"Cuadro de ocupación"}>

				<div>
					<TextField sx={{my: 2, width: "5", float: "right"}}
							   value={year}
							   type={"number"}
							   size={"small"}
							   onChange={(e) => setYear(parseInt(e.target.value))}
					/>
				</div>

				{!occupation &&
					<>Loading....</>
				}
				{occupation && (
					<>
						<List style={{display: "flex", flexDirection: "row", padding: 0}}>
							{months.map((month, yIdx) => (
								<ListItem
									key={yIdx}
									button
									divider
									selected={selectedMonth == month}
									color={"default"}
									onClick={() => changeMonth(month)}>
									{month}
								</ListItem>
							))}
						</List>


						<TabContext value={selectedMainTab}>
							<Box sx={{borderBottom: 1, borderColor: 'divider'}}>
								<TabList onChange={(event: React.SyntheticEvent, newValue: string) => {
									setSelectedMainTab(newValue);
								}}>
									<Tab label="Grupal" value="group"/>
									<Tab label="Individual" value="individual"/>
								</TabList>
							</Box>
							<TabPanel sx={{p: 0, minHeight: "100%"}} value="group">
								<OccupationGroup occupation={occupation} year={year} month={selectedMonth}/>
							</TabPanel>
							<TabPanel sx={{p: 0, minHeight: "100%"}} value="individual">
								<OccupationIndividual occupation={occupation} year={year} month={selectedMonth}/>
							</TabPanel>
						</TabContext>

					</>
				)}

			</AdminInner>
		</>
	);
};