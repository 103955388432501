import {
    Recruitment,
    RecruitmentRestControllerApiFactory
} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {useApiManager} from "../../../../components/utils/api";
import {Grid} from "@mui/material";
import {COLOR_GRIS} from "../../../../components/layout/colors";
import React, {useEffect, useState} from "react";


export const RecruitmentListPage = () => {
    const apiManager = useApiManager();

    const listConfig: AppListConfig<Recruitment> = {
        title: "Procesos de selección ",
        onClick: "/app/admin/recruitment/recruitment",
        decorator: (bean: Recruitment) => (
            <Grid
                container
                style={{color: bean.status === "FINISHED" || bean.status === "DESISTED" ? COLOR_GRIS : ""}}>
                <Grid item sm={7}>
                    {bean.title}
                </Grid>
                <Grid item sm>
                    {bean.customer?.shortName}
                </Grid>
                <Grid item sm>
                    {bean.status}
                </Grid>
                <Grid item sm={2}>
                    {bean.visible ? "visible" : ""}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            
            const ctrl = apiManager.factory(RecruitmentRestControllerApiFactory);
            return ctrl.pageRecruitment(currentPage, size, undefined, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig}/>
        </>
    );
};

export const useRecruitment = (id: number | undefined): [(Recruitment | undefined), React.Dispatch<React.SetStateAction<Recruitment | undefined>>] => {
    const apiManager = useApiManager();
    const [getter, setter] = useState<Recruitment | undefined>();
    const ctrl = apiManager.factory(RecruitmentRestControllerApiFactory);

    useEffect(() => {
        if (id) {
            apiManager
                .execute(ctrl.getRecruitment(id))
                .then(c => setter(c));

        }
    }, [id]);
    return [getter, setter];
};