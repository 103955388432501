import * as React from "react";
import {UserAuth, UserAuthItem} from "arteco-api-client-ts";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {ReduxState, updateAuthRedux, useAppDispatch, useAppSelector} from "../../store";
import {envVars} from "./env_vars";
import {decodeB64, encodeB64} from "./base64";


const AUTH_LOCAL_STORE_KEY = "auth";
type AppStateDispatcher = (newState: AuthState) => void;

export type AuthState = {
    user: UserAuth | undefined;
    selectedAuthority: UserAuthItem | undefined;
    p64: string | undefined;
    u: string | undefined;
}

function stateSaveLocally(state: AuthState) {
    const stateStr = JSON.stringify(state);
    localStorage.setItem(AUTH_LOCAL_STORE_KEY, stateStr);
}

function stateLoadLocally(): AuthState | undefined {
    const stateStr = localStorage.getItem(AUTH_LOCAL_STORE_KEY);
    try {
        if (stateStr) {
            return JSON.parse(stateStr) as AuthState;
        }
    } catch (e) {
        // do nothing
    }
    return undefined;
}

export function randomString(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

function stateInitial(): AuthState {
    return stateLoadLocally() || {} as AuthState;
}

export const AUTH_STATE_INITIAL: AuthState = stateInitial();


export function useAuthManager(): AuthManager {
    const navigate = useNavigate();
    const state = useAppSelector((state: ReduxState) => state.auth)
    const dispatch = useAppDispatch()
    if (!state) throw "No appState in userContext. Consider inject UserContextProvider";
    if (!dispatch) throw "No dispatch in userContext. Consider inject UserContextProvider";
    return new SimpleAuthManager(navigate, state,
        (newState) => {
            dispatch(updateAuthRedux(newState))
        });
}

export interface AuthManager {
    getAuthorities(): UserAuthItem[];

    saveSelectedAuthority(auth: UserAuthItem): AuthState;

    getSelectedAuthority(): UserAuthItem | undefined;

    saveCredentials(username: string, password: string, user: UserAuth): AuthState;

    tryRecoverState(): AuthState | undefined;

    doLogout(): void;

    isLogged(): boolean;

    getUsername(): string | undefined;

    getPassword(): string | undefined;

    getEmail(): string | undefined;

    getImageSrc(): string | undefined;

    isSuperAdmin(): boolean;

    getUserId(): number | undefined;
}


class SimpleAuthManager implements AuthManager {
    private readonly navigate: NavigateFunction;
    private readonly dispatch: AppStateDispatcher;
    private appState: AuthState; /* non null */

    constructor(navigate: NavigateFunction, appState: AuthState, dispatch: AppStateDispatcher) {
        this.navigate = navigate;
        this.appState = appState;
        this.dispatch = dispatch;
    }

    isLogged(): boolean {
        return this.appState.user?.email != undefined;
    }

    getUsername(): string | undefined {
        return this.appState.user?.username;
    }

    getPassword(): string | undefined {
        return this.appState.p64 ? decodeB64(this.appState.p64) : undefined;
    }

    getEmail(): string | undefined {
        return this.appState.user?.email;
    }

    getImageSrc(): string | undefined {
        //TODO: pendiente de implementar
        return undefined;
    }

    getAuthorities(): UserAuthItem[] {
        return this.appState.user?.authorities || [];
    }

    getSelectedAuthority(): UserAuthItem | undefined {
        return this.appState.selectedAuthority;
    }

    isSuperAdmin() {
        return this.appState.user?.superAdmin || false;
    }

    doLogout(): void {
        this.dispatch(AUTH_STATE_INITIAL);
        this.navigate("/", {replace: true});
        localStorage.removeItem(AUTH_LOCAL_STORE_KEY);
    }

    getUserId(): number | undefined {
        return this.appState.user?.id;
    }

    saveSelectedAuthority(auth: UserAuthItem): AuthState {
        const newState = {
            ...this.appState,
            selectedAuthority: auth,
        } as AuthState;
        return this.modifyState(newState);
    }

    saveCredentials(username: string, password: string, user: UserAuth): AuthState {
        if (!user.username || !user.email || !user.authorities) {
            throw new Error("No user info retrieved");
        }
        const newState = {
            ...this.appState,
            user: user,
            p64: encodeB64(password),
            u: username
        } as AuthState;
        return this.modifyState(newState);
    }

    tryRecoverState(): AuthState | undefined {
        const newState = stateLoadLocally();
        if (newState) {
            return this.modifyState(newState);
        }
        return undefined;
    }

    modifyState(state: AuthState): AuthState {
        this.appState = state;
        this.dispatch(state);
        stateSaveLocally(state);
        return state;
    }
}
