import {List, ListItem, ListItemText, Typography} from "@mui/material";
import AdminInner from "../../../components/layout/admin_inner";
import {H1, Typographies} from "../../../components/utils/typography_consts";
import {AdminCard} from "../../../components/layout/admin_card";
import {
    NewCandidate, ProgressCandidate,
    RecruitmentStats, RecruitmentStatsRestControllerApiFactory
} from "arteco-api-client-ts";
import React, {useEffect, useState} from "react";
import {AdminCards} from "../../../components/layout/admin_card/admin_cards";
import {useNavigate} from "react-router-dom";
import {useApiManager} from "../../../components/utils/api";

export const AdminRecruitmentPage = () => {
    const navigate = useNavigate();
    const apiManager = useApiManager();
    
    const ctrl = apiManager.factory(RecruitmentStatsRestControllerApiFactory);

    const [stats, setStats] = useState<RecruitmentStats>({});

    useEffect(() => {
        apiManager.execute(ctrl.getRecruitmentStats())
            .then(stats => setStats(stats));
    }, []);

    return (
        <AdminInner>

            <Typography variant={Typographies.h3} component={H1}>
                Módulo de selección
            </Typography>
            <AdminCards>
                <AdminCard title={"Candidatos a evaluar"}>
                    <small>Mostrando candidatos con puntuación 100</small>
                    <List>
                        {stats.newCandidates && stats.newCandidates.map((b: NewCandidate, i: number) => (
                            <ListItem key={"nc-" + i}
                                      button
                                      onClick={_ => navigate("/app/admin/recruitment/candidate/" + b.candidateId)}
                            >{b.candidateName}</ListItem>
                        ))}
                        {!stats.newCandidates && (
                            <li>No hay nuevos candidatos</li>
                        )}
                    </List>
                </AdminCard>

                <AdminCard title={"Candidatos en procesos"}>
                    <small>Personas en procesos con estado no finalizado</small>
                    <List>
                        {stats.progressCandidates && stats.progressCandidates.map((b: ProgressCandidate, i: number) => (
                            <ListItem key={"pc-" + i}
                                      button
                                      title={b.recruitmentName}
                                      onClick={_ => navigate("/app/admin/recruitment/candidate/undefined/application/" + b.applicationId)}
                            >
                                <ListItemText primary={b.candidateName} secondary={b.recruitmentName+": " +b.state?.name} />
                            </ListItem>
                        ))}
                        {!stats.progressCandidates && (
                            <li>No hay candidatos en procesos activos</li>
                        )}
                    </List>
                </AdminCard>

            </AdminCards>
        </AdminInner>
    );
};