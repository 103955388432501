import {Template, TemplateRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {useApiManager} from "../../../../components/utils/api";

export const TemplateListPage = () => {
    const apiManager = useApiManager();

    const listConfig: AppListConfig<Template> = {
        title: "Plantillas ",
        onClick: "/app/admin/company/template",
        decorator: (bean: Template) => bean.name,
        supplier: async (currentPage, size, term, showDismissed) => {
            
            const ctrl = apiManager.factory(TemplateRestControllerApiFactory);
            return ctrl.pageTemplate(currentPage, size, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};