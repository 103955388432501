import {
    Candidate,
    CandidateLevelSolution,
    CandidateLevelSolutionRestControllerApiFactory,
    CandidateRestControllerApiFactory
} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {COLOR_DISMISSED} from "../../../../components/layout/colors";
import {Box, Grid} from "@mui/material";
import {useApiManager} from "../../../../components/utils/api";
import React, {useEffect, useState} from "react";
import {dateToString} from "../../../../components/utils/dates";
import {useParams} from "react-router-dom";
import {formatLength} from "../../../../components/form/form_inputs/form_file";
import {parsePathId} from "../../../../components/form/form_utils";

export const CandidateLevelSolutionListPage = () => {
    const apiManager = useApiManager();
    const candidateCtrl = apiManager.factory(CandidateRestControllerApiFactory);

    const params = useParams();
    const candidateId = parsePathId(params, "candidateId");

    const [pending, setPending] = useState<boolean | undefined>(undefined);
    const [trigger, setTrigger] = useState<number>(0);
    const [candidate, setCandidate] = useState<Candidate | undefined>(undefined);

    useEffect(() => {
        if (candidateId) {
            apiManager
                .execute(candidateCtrl.getCandidate(candidateId))
                .then(candidate => {
                    setCandidate(candidate);
                    setTrigger(trigger + 1);
                });
        } else {
            setCandidate(undefined);
            setTrigger(trigger + 1);
        }
    }, [candidateId]);

    const listConfig: AppListConfig<CandidateLevelSolution> = {
        title: "Soluciones de Pruebas de Nivel " + (candidate ? " del candidato = " + candidate.surnamesAndName : ""),
        onClick: candidateId ? "/app/admin/recruitment/candidate/"+candidateId+"/solution" : "/app/admin/recruitment/level/solution",
        decorator: (bean: CandidateLevelSolution) => (
            <Grid container style={{color: bean.dismissed ? COLOR_DISMISSED : ""}}>
                <Grid item sm={2}>
                    {dateToString(bean.created)}
                </Grid>
                <Grid item sm={3}>
                    {bean.test?.name || ""}
                </Grid>
                <Grid item sm={4}>
                    {bean.candidate.surnamesAndName}
                </Grid>
                <Grid item sm>
                    {formatLength(bean.attachment)}
                </Grid>
                <Grid item sm={1}>
                    {bean.points}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            
            const ctrl = apiManager.factory(CandidateLevelSolutionRestControllerApiFactory);
            return ctrl.pageCandidateLevelSolution(currentPage, size, undefined, candidateId, pending, term, showDismissed);
        },
        actions: [
            {
                action: () => {
                    const newPending = pending ? undefined : true;
                    setPending(newPending);
                    setTrigger(trigger + 1);
                },
                color: pending ? "primary" : "secondary",
                label: <Box>
                    Pendientes
                </Box>

            },
        ]
    };

    return (
        <>
            <AppList config={listConfig} trigger={trigger}/>
        </>
    );
};