import {BillingLayout} from "./pages/admin/billing/layout";
import {Route} from "react-router-dom";
import {AdminBillingPage} from "./pages/admin/billing";
import {BillListPage} from "./pages/admin/billing/bill";
import {BillBatchPage} from "./pages/admin/billing/bill/batch";
import {BillEditPage} from "./pages/admin/billing/bill/form";
import {CustomerListPage} from "./pages/admin/billing/customer";
import {CustomerEditPage} from "./pages/admin/billing/customer/form";
import {OccupationPage} from "./pages/admin/billing/occupation";
import {WikiPageListPage} from "./pages/admin/employee/wiki";
import {SECTION_BILLING, WikiPageEditPage} from "./pages/admin/employee/wiki/form";
import {RedmineMappingListPage} from "./pages/admin/billing/mapping";
import {RedmineMappingEditPage} from "./pages/admin/billing/mapping/form";
import {RedmineImportPage} from "./pages/admin/billing/mapping/import";
import * as React from "react";
import SideBarItem from "./sidebar_item";
import {Dashboard, East, Euro, GridOn, ImportExport, NoteAlt, People, Receipt, Savings} from "@mui/icons-material";
import {BillDiscountListPage} from "./pages/admin/billing/discount";
import {BillDiscountEditPage} from "./pages/admin/billing/discount/form";
import {DedicationBatchPage} from "./pages/admin/billing/occupation/dedication/dedication_batch";
import {BudgetEditPage} from "./pages/admin/billing/budget/form";
import {BudgetListPage} from "./pages/admin/billing/budget";
import {OverviewPage} from "./pages/admin/billing/overview";


const AdminBillingRoutes = () => {
	return (
		<Route path="/app/admin/billing" element={<BillingLayout/>}>
			<Route index element={<AdminBillingPage/>}/>

			<Route path="/app/admin/billing/overview" element={<OverviewPage/>}/>

			<Route path="/app/admin/billing/bill" element={<BillListPage/>}/>
			<Route path="/app/admin/billing/bill-batch" element={<BillBatchPage/>}/>
			<Route path="/app/admin/billing/bill/:id" element={<BillEditPage/>}/>
			<Route path="/app/admin/billing/bill/new" element={<BillEditPage/>}/>

			<Route path="/app/admin/billing/discount" element={<BillDiscountListPage/>}/>
			<Route path="/app/admin/billing/discount/:id" element={<BillDiscountEditPage/>}/>
			<Route path="/app/admin/billing/discount/new" element={<BillDiscountEditPage/>}/>

			<Route path="/app/admin/billing/budget" element={<BudgetListPage/>}/>
			<Route path="/app/admin/billing/budget/:id" element={<BudgetEditPage/>}/>
			<Route path="/app/admin/billing/budget/new" element={<BudgetEditPage/>}/>

			<Route path="/app/admin/billing/customer" element={<CustomerListPage/>}/>
			<Route path="/app/admin/billing/customer/:id"
				   element={<CustomerEditPage/>}/>
			<Route path="/app/admin/billing/customer/new"
				   element={<CustomerEditPage/>}/>
			<Route path="/app/admin/billing/occupation" element={<OccupationPage/>}/>
			<Route path="/app/admin/billing/wiki"
				   element={<WikiPageListPage section={SECTION_BILLING}/>}/>
			<Route path="/app/admin/billing/wiki/:id"
				   element={<WikiPageEditPage section={SECTION_BILLING}/>}/>
			<Route path="/app/admin/billing/wiki/new"
				   element={<WikiPageEditPage section={SECTION_BILLING}/>}/>
			<Route path="/app/admin/billing/mapping"
				   element={<RedmineMappingListPage/>}/>
			<Route path="/app/admin/billing/mapping/:id"
				   element={<RedmineMappingEditPage/>}/>
			<Route path="/app/admin/billing/mapping/new"
				   element={<RedmineMappingEditPage/>}/>
			<Route path="/app/admin/billing/import"
				   element={<RedmineImportPage/>}/>
			<Route path="/app/admin/billing/dedication-batch"
				   element={<DedicationBatchPage/>}/>
		</Route>
	);
}

export default AdminBillingRoutes;


export const AdminBillingMenu = () => {
	return (
		<>
			<SideBarItem
				href={"/app/admin/billing/overview"}
				label={"Panel general"}
				icon={<Dashboard/>}
			/>
			<SideBarItem
				href={"/app/admin/billing/occupation"}
				label={"Ocupación"}
				icon={<GridOn/>}
			/>
			<SideBarItem
				href={"/app/admin/billing/bill"}
				label={"Facturas"}
				icon={<Receipt/>}
			/>
			<SideBarItem
				href={"/app/admin/billing/customer"}
				label={"Clientes"}
				icon={<People/>}
			/>

			<SideBarItem
				href={"/app/admin/billing/budget"}
				label={"Presupuestos"}
				icon={<Euro/>}
			/>

			<SideBarItem
				href={"/app/admin/billing/discount"}
				label={"Descuentos"}
				icon={<Savings/>}
			/>

			<SideBarItem
				href={"/app/admin/billing/wiki"}
				label={"Wiki"}
				icon={<NoteAlt/>}
			/>

			<SideBarItem
				href={"/app/admin/billing/import"}
				label={"Importar redmine."}
				icon={<ImportExport/>}
			/>

			<SideBarItem
				href={"/app/admin/billing/dedication-batch"}
				label={"Batch dedicaciones"}
				icon={<ImportExport/>}
			/>

			<SideBarItem
				href={"/app/admin/billing/mapping"}
				label={"Mappings"}
				icon={<East/>}
			/>
		</>
	);
}