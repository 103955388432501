import React from "react";

import {useApiManager} from "../../../../components/utils/api";
import {SkillLevel, SkillLevelRestControllerApiFactory} from "arteco-api-client-ts";

import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Grid} from "@mui/material";
import {useForm, UseFormProps} from "../../../../components/form/form_model";

export const SkillLevelEditPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(SkillLevelRestControllerApiFactory);

    const form = useForm({
        fetcher: (id) => ctrl.getSkillLevel(id),
        updater: (bean) => ctrl.saveSkillLevel(bean),
        remover: (id) => ctrl.deleteOrDismissSkillLevel(id),
        factory: () => {
            return {
            } as SkillLevel;
        },
    } as UseFormProps<SkillLevel>);

    return (
        <>
            <FormContent form={form} title={"Formulario de niveles de habilidad"}>
                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput type={"number"} field={"level"} label={"Nivel"} />
                    </Grid>
                    <Grid item sm>
                        <FormInput type={"text"} field={"label"} label={"Etiqueta"} />
                    </Grid>
                </Grid>
                <FormInput type={"text"} field={"description"} label={"Descripción"} rows={3} />
            </FormContent>
        </>
    );
};