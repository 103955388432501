import AppList, {AppListConfig} from "../../../../../components/layout/list";
import {
    Person,
    PersonDocument,
    PersonDocumentRestControllerApiFactory,
    PersonDocumentType,
    PersonRestControllerApiFactory,
} from "arteco-api-client-ts";
import React, {useEffect, useState} from "react";
import {useApiManager} from "../../../../../components/utils/api";
import {useNavigate, useParams} from "react-router-dom";
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {dateToString} from "../../../../../components/utils/dates";
import {usePerson} from "../index";
import {fileLocalSave} from "../../../../../components/form/form_inputs/form_file";
import DownloadIcon from '@mui/icons-material/Download';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import {parsePathId} from "../../../../../components/form/form_utils";
import {employeeModalStyle} from "../../../../employee/modal";
import {types, useType} from "../../../../../components/form/types";

export const PersonDocumentListPage = () => {
    const theme = useTheme();
    const params = useParams();
    const apiManager = useApiManager();
    
    const navigate = useNavigate();

    const peopleCtrl = apiManager.factory(PersonRestControllerApiFactory);
    const perDocType = useType(types.personDocumentTypes);


    const personId = parsePathId(params, "personId");
    const [person, setPerson] = usePerson(personId);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const [exportYear, setExportYear] = useState<string>('');
    const [exportType, setExportType] = useState<string>('');
    const [exportPerson, setExportPerson] = useState<string>('');
    const [docTypes, setDocTypes] = useState<PersonDocumentType[]>([]);
    const [people, setPeople] = useState<Person[]>([]);
    const [exportDisabled, setExportDisabled] = useState<boolean>(false);

    const documentCtrl = apiManager.factory(PersonDocumentRestControllerApiFactory);

    const getPersonId = (document: PersonDocument | undefined): number | undefined => {
        if (person) {
            return person.id;
        } else if (document) {
            return document.person.id;
        } else {
            return undefined;
        }
    }

    useEffect(() => {
        apiManager.execute(peopleCtrl.listPerson())
            .then(people => setPeople(people));
        perDocType.supplier()
            .then(types => setDocTypes(types))
    }, []);

    const listConfig: AppListConfig<PersonDocument> = {
        title: "Archivos de " + (person ? person.surnamesAndName : " Empleados"),
        onClick: (document: PersonDocument) => {
            const personId = getPersonId(document);
            if (personId) {
                navigate("/app/admin/employee/person/" + personId + "/document/" + document.id);
            } else {
                alert("No personId to forward!");
            }
        },
        decorator: (bean: PersonDocument) =>
            <Grid container style={{color: bean.visible ? theme.palette.info.main : "inherit"}}>
                <Grid item sm={2}>{dateToString(bean.created)}</Grid>
                {!personId &&
                    <Grid item sm={3}>{bean.person.surnamesAndName}</Grid>
                }
                <Grid item sm={2}>{bean.type?.name}</Grid>
                <Grid item sm={3}>
                    <small>{bean.title.length > 33 ? bean.title.substring(0, 33) + "..." : bean.title}
                        {bean.expiration ? "(*)" : ""}
                    </small>
                </Grid>
                <Grid item sm={2} textAlign={"right"}>
                    <Button variant={"text"} size={"small"}
                            title={"Descargar con nombre de fichero"}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (bean.id) {
                                    apiManager.executeRaw(documentCtrl.viewPersonDocument(bean.id))
                                        .then((resp) => fileLocalSave(resp));
                                }
                            }}>
                        <DownloadIcon/>
                    </Button>
                    <Button variant={"text"} size={"small"}
                            title={"Descargar con nombre de persona"}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (bean.id) {
                                    apiManager.executeRaw(documentCtrl.viewPersonDocument(bean.id))
                                        .then((resp) => fileLocalSave(resp, bean.person.surnamesAndName));
                                }
                            }}>
                        <SimCardDownloadIcon/>
                    </Button>
                </Grid>
            </Grid>,
        supplier: async (currentPage, size, term, showDismissed) => {
            return documentCtrl.pagePersonDocument(
                currentPage,
                size,
                undefined,
                personId,
                undefined,
                undefined,
                undefined,
                term,
                showDismissed
            );
        },
        actions: [
            {
                label: "Exportar",
                action: () => setModalOpen(true)
            }
        ]
    };


    return (
        <>
            <AppList config={listConfig} appendable={person != undefined} onAppend={() => {
                const personId = getPersonId(undefined);
                if (personId) {
                    navigate("/app/admin/employee/person/" + personId + "/document/new");
                } else {
                    alert("No personId to forward!");
                }
            }}/>

            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={employeeModalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Exportar ficheros por lotes
                    </Typography>
                    <p>
                        <small>Todos los campos son opcionales</small>
                    </p>
                    <Box id="modal-modal-description" sx={{mt: 2}}>
                        <TextField sx={{mb: 2}} fullWidth label={"Año"} type={"number"} value={exportYear}
                                   onChange={e => setExportYear(e.target.value)}/>
                        <FormControl fullWidth sx={{mb: 2}}>
                            <InputLabel id="docTypeLabelId">Tipo documento</InputLabel>
                            <Select
                                fullWidth
                                value={exportType}
                                label={"Tipo documento"}
                                labelId={"docTypeLabelId"}
                                onChange={e => setExportType(e.target.value)}>
                                <MenuItem value={''}>Todos</MenuItem>
                                {docTypes && docTypes.map(docType => (
                                    <MenuItem value={docType.id}>{docType.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{mb: 2}}>
                            <InputLabel id="personLabelId">Persona</InputLabel>
                            <Select
                                fullWidth
                                sx={{mb: 1}}
                                value={exportPerson}
                                label="Persona"
                                labelId="personLabelId"
                                onChange={e => setExportPerson(e.target.value)}>
                                <MenuItem value={''}>Todos</MenuItem>
                                {people && people.map(person => (
                                    <MenuItem value={person.id}>{person.surnamesAndName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button variant={"contained"} disabled={exportDisabled} onClick={_ => {
                            setExportDisabled(true);
                            apiManager.executeRaw(documentCtrl.exportPersonDocument(
                                parseInt(exportYear) ? parseInt(exportYear) : undefined,
                                parseInt(exportType) ? parseInt(exportType) : undefined,
                                parseInt(exportPerson) ? parseInt(exportPerson) : undefined,
                            )).then((resp) => {
                                fileLocalSave(resp);
                                setExportDisabled(false);
                                setModalOpen(false)
                            }, _ => {
                                setExportDisabled(false);
                                setModalOpen(false);
                            });
                        }}>Exportar</Button>
                    </Box>

                </Box>
            </Modal>
        </>
    );
};