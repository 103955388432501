import React from "react";
import {PersonReminder, PersonReminderRestControllerApiFactory} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import FormToggle from "../../../../components/form/form_inputs/form_toggle";


export const PersonReminderEditPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(PersonReminderRestControllerApiFactory);
    


    const form = useForm({
        fetcher: (id) => ctrl.getPersonReminder(id),
        updater: (bean) => ctrl.savePersonReminder(bean),
        remover: (id) => ctrl.deleteOrDismissPersonReminder(id),
        factory: () => {
            return {} as PersonReminder;
        },

    } as UseFormProps<PersonReminder>);

    return (
        <>
            <FormContent form={form} title={"Formulario de Recordatorio"}>

                <FormToggle field={"disabled"} label={"Desactivado"}/>

                <FormInput type={"text"} field={"content"}
                           label={"Contenido"} rows={10}/>

            </FormContent>
        </>
    );
};