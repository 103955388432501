import {
	Button,
	CircularProgress,
	Grid,
	lighten,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme
} from "@mui/material";
import {H1, Typographies,} from "../../components/utils/typography_consts";
import AdminInner from "../../components/layout/admin_inner";
import {useApiManager} from "../../components/utils/api";
import {Aggregated, DayHomeStat, EmpStatsRestControllerApiFactory, MonthHomeStat} from "arteco-api-client-ts";
import {useLastEvaluation} from "./employee_util";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {EmpReminders} from "./reminders";
import useSWR from "swr";
import {useState} from "react";
import {Box} from "@mui/system";
import moment from "moment";

export const EmployeePage = () => {
	const apiManager = useApiManager();
	const statCrl = apiManager.factory(EmpStatsRestControllerApiFactory);

	const {data: stats, isLoading} = useSWR("home.user.stats", () => apiManager.execute(statCrl.getHomeStats()));
	const [detailed, setDetailed] = useState<boolean>(false);

	return (
		<AdminInner>
			<Typography variant={Typographies.h3} component={H1}>
				Bienvenido al Portal del Empleado
			</Typography>


			{isLoading && (
				<>
					<Box my={3}>
						<CircularProgress/><br/> Cargando estadísticas....
					</Box>
				</>
			)}

			{stats && stats.previous && stats.actual && stats.actual.budgets && stats.previous.budgets && (
				<>

					<Typography variant="h4" style={{marginTop: "20px"}}>Dedicación de presupuestos</Typography>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Typography variant="h6" style={{marginTop: "20px"}}>Mes Actual</Typography>
							{RenderBudgets(stats.actual)}
							<small>Actualizado a las {moment(stats.actual.tstamp).format("DD/MM HH:mm")}</small>
						</Grid>
						<Grid item xs={6}>
							<Typography variant="h6" style={{marginTop: "20px"}}>Previo</Typography>
							{RenderBudgets(stats.previous)}
							<small>Actualizado a las {moment(stats.previous.tstamp).format("DD/MM HH:mm")}</small>
						</Grid>
					</Grid>

					<Typography variant="h4" style={{marginTop: "2em"}}>
						Calendario &nbsp;
						<Button onClick={() => setDetailed(!detailed)}>
							{detailed && (<>Ocultar</>)}
							{!detailed && (<>Mostrar</>)}
							&nbsp; DETALLES
						</Button>
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<RenderMonth detailed={detailed} title={"Mes actual"} stats={stats.actual}/>
						</Grid>
						<Grid item xs={6}>
							<RenderMonth detailed={detailed} title={"Previo"} stats={stats.previous}/>
						</Grid>
					</Grid>

				</>
			)}

			<EmpReminders/>
			<ForceEvaluation/>
		</AdminInner>
	);
};

const ForceEvaluation = () => {
	const [forceEvaluation, setForceEvaluation] = useLastEvaluation();
	return (
		<>
			{forceEvaluation && (
				<Alert sx={{my: 2}} color={"warning"}>
					<AlertTitle>Tarea pendiente</AlertTitle>
					<p>
						Hace tiempo que no completas una EVALUACIÓN.<br/>
						Cumplimenta una y se habilitará todas las opciones de nuevo.<br/>
						Gracias
					</p>
				</Alert>
			)}
		</>
	)
}

interface RenderMonthProps {
	stats: MonthHomeStat;
	title: string;
	detailed: boolean;
}

const RenderMonth = ({stats, title, detailed}: RenderMonthProps) => (
	<>
		<Typography variant="h5" gutterBottom>
			{title} ({stats.month}/{stats.year})
		</Typography>

		<RenderCalendar stats={stats} detailed={detailed}/>
	</>
);

interface RenderCalendarProps {
	stats: MonthHomeStat;
	detailed: boolean;
}

const RenderCalendar = ({stats, detailed}: RenderCalendarProps) => {
	const theme = useTheme();

	const color = (stats.totalPlannedHours || 0) <= (stats.totalRedmineHours || 0)
		? theme.palette.success.light
		: theme.palette.warning.light
	;

	const isHoursDiff = (day: DayHomeStat) => {
		return Math.abs((day.redmineHours || 0) - (day.plannedHours || 0)) > 0;
	}

	return (
		<TableContainer sx={{width: '100%'}}>
			<Table>
				<TableHead color={theme.palette.info.main}>
					<TableRow sx={{backgroundColor: theme.palette.info.dark}}>
						<TableCell sx={{color: "white", backgroundColor: "inherit"}}>{detailed && "Fecha"}</TableCell>
						<TableCell sx={{color: "white", backgroundColor: "inherit"}}>{detailed && "Ausencia"}</TableCell>
						<TableCell align={"right"} sx={{color: "white", backgroundColor: "inherit"}}>Planificadas</TableCell>
						<TableCell align={"right"} sx={{color: "white", backgroundColor: "inherit"}}>Redmine</TableCell>
					</TableRow>
				</TableHead>
				{detailed && (
					<TableBody>
						{stats.calendar && stats.calendar.map((day, index) => (
							<TableRow key={index} sx={{
								color: (day.plannedHours || 0) < 1 ? "gray" : undefined
							}}>
								<TableCell sx={{color: "inherit", backgroundColor: "inherit"}}>
									<Typography noWrap>{day.date}</Typography>
								</TableCell>
								<TableCell sx={{color: "inherit", backgroundColor: "inherit"}}>
									{day.vacationName || day.holidayName || ""}
								</TableCell>
								<TableCell sx={{color: "inherit", backgroundColor: "inherit"}}
										   align={"right"}>
									{day.plannedHours?.toFixed(1)}</TableCell>
								<TableCell sx={{
									color: "inherit", background: isHoursDiff(day)
										? lighten(theme.palette.warning.light, 0.7)
										: lighten(theme.palette.success.light, 0.7)
								}}
										   align={"right"}>
									{day.redmineHours?.toFixed(1)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				)}
				<TableRow>
					<TableCell sx={{backgroundColor: lighten(color, 0.7), fontWeight: "bold"}} colSpan={2}>Total</TableCell>
					<TableCell sx={{backgroundColor: lighten(color, 0.7), fontWeight: "bold"}}
							   align={"right"}>{stats.totalPlannedHours?.toFixed(1)}</TableCell>
					<TableCell sx={{backgroundColor: lighten(color, 0.7), fontWeight: "bold"}}
							   align={"right"}>{stats.totalRedmineHours?.toFixed(1)}</TableCell>
				</TableRow>
			</Table>
		</TableContainer>
	);
}

const RenderBudgets = (stats: MonthHomeStat) => {
	const theme = useTheme();

	const color = (stats.totalPlannedHours || 0) <= (stats.totalBudgetHours || 0)
		? theme.palette.success.light
		: theme.palette.warning.light
	;

	const budgetZero = (budget: Aggregated) => {
		return ((budget.id || 0) > 0)
			? undefined
			: {backgroundColor: lighten(theme.palette.error.light, .3), color: "white"};
	};

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow sx={{backgroundColor: theme.palette.info.dark}}>
						<TableCell sx={{color: "white", backgroundColor: "inherit"}}>ID</TableCell>
						<TableCell sx={{color: "white", backgroundColor: "inherit"}}>Nombre</TableCell>
						<TableCell align={"right"} sx={{color: "white", backgroundColor: "inherit"}}>Total Horas</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{stats.budgets && stats.budgets.map((budget) => (
						<TableRow key={budget.id}>
							<TableCell sx={budgetZero(budget)}>{budget.id || ""}</TableCell>
							<TableCell sx={budgetZero(budget)}>{budget.name}</TableCell>
							<TableCell sx={budgetZero(budget)} align={"right"}>
								{budget.total?.toFixed(1)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
				<TableRow>
					<TableCell sx={{backgroundColor: lighten(color, 0.7), fontWeight: "bold"}} colSpan={2}>Total</TableCell>
					<TableCell sx={{backgroundColor: lighten(color, 0.7), fontWeight: "bold"}}
							   align={"right"}>{stats.totalBudgetHours?.toFixed(1)}</TableCell>
				</TableRow>
			</Table>
		</TableContainer>
	);
}