import React from "react";
import {Attachment, Payroll, PayrollRestControllerApiFactory, Person,} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import FormAutocomplete from "../../../../components/form/form_inputs/form_autocomplete";
import {types} from "../../../../components/form/types";
import {Grid} from "@mui/material";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import FormFile from "../../../../components/form/form_inputs/form_file";
import {useApiManager} from "../../../../components/utils/api";

export const PayrollEditPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(PayrollRestControllerApiFactory);
    

    const form = useForm({
        fetcher: (id) => ctrl.getPayroll(id),
        updater: (bean) => ctrl.savePayroll(bean),
        remover: (id) => ctrl.deleteOrDismissPayroll(id),
        factory: () => {
            const person = {} as Person;
            const attachment = {} as Attachment;
            return {
                person: person,
                periodFrom: "",
                periodTo: "",
                attachment,
            } as Payroll;
        },
    } as UseFormProps<Payroll>);

    return (
        <>
            <FormContent form={form} title={"Formulario de Nómina"}>
                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormAutocomplete field={"person"} label={"Persona"} type={types.people} />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm={3}>
                        <FormInput field={"periodFrom"} type={"date"} label={"Desde"} />
                    </Grid>
                    <Grid item sm={3}>
                        <FormInput field={"periodTo"} type={"date"} label={"Hasta"} />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm={3}>
                        <FormInput
                            field={"cotizContCom"}
                            type={"number"}
                            label={"Cotiz. Conting. Comunes"}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <FormInput
                            field={"cotizFormProf"}
                            type={"number"}
                            label={"Cotiz. Form. Profesional"}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <FormInput
                            field={"cotizDesempleo"}
                            type={"number"}
                            label={"Cotiz Desempleo"}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <FormInput
                            field={"totalCotizaciones"}
                            type={"number"}
                            label={"Total Cotizaciones"}
                            disabled={true}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm={3}>
                        <FormInput field={"bruto"} type={"number"} label={"Bruto"} />
                    </Grid>
                    <Grid item sm={3}>
                        <FormInput
                            field={"retencionIrpf"}
                            type={"number"}
                            label={"Retención IRPF"}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <FormInput field={"totalLiquido"} type={"number"} label={"Total líquido"} />
                    </Grid>
                    <Grid item sm={3}>
                        <FormInput
                            field={"segSocialEmpresa"}
                            type={"number"}
                            label={"Seg. Soc. Empresa"}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm={3}>
                        <FormInput field={"costeEmpresa"} type={"number"} label={"Coste Empresa"} />
                    </Grid>
                    <Grid item sm={4}>
                        <FormInput
                            field={"externalId"}
                            type={"text"}
                            label={"Identificador externo"}
                        />
                    </Grid>
                </Grid>

                <FormFile
                    field={"attachment"}
                    label={"Nómina"}
                    onDownload={() => apiManager.executeRaw(ctrl.downloadPayroll(form.getIdOrErr()))}
                />
            </FormContent>
        </>
    );
};