import React from "react";

import {Grid} from "@mui/material";
import {FormInput} from "../../../../../components/form/form_inputs/form_input";
import {FormContent} from "../../../../../components/form/form_inputs/form_buttons";
import {Person, PersonDocument, PersonDocumentRestControllerApiFactory,} from "arteco-api-client-ts";
import {useForm, UseFormProps,} from "../../../../../components/form/form_model";
import {useApiManager} from "../../../../../components/utils/api";
import {useParams} from "react-router-dom";
import FormFile from "../../../../../components/form/form_inputs/form_file";
import {usePerson} from "../index";
import FormToggle from "../../../../../components/form/form_inputs/form_toggle";
import FormSelect from "../../../../../components/form/form_inputs/form_select";
import {types} from "../../../../../components/form/types";
import {parsePathId} from "../../../../../components/form/form_utils";

export const PersonDocumentEditPage = () => {
    const params = useParams();
    const personId = parsePathId(params, "personId");
    const manager = useApiManager();
    const ctrl = manager.factory(PersonDocumentRestControllerApiFactory);
    const apiManager = useApiManager();

    const [person, setPerson] = usePerson(personId);

    const form = useForm({
        fetcher: (id) => ctrl.getPersonDocument(id),
        updater: (bean) => ctrl.savePersonDocument(bean),
        remover: (id) => ctrl.deleteOrDismissPersonDocument(id),
        factory: () => {
            return {
                person: {id: personId} as Person,
            } as PersonDocument;
        },
    } as UseFormProps<PersonDocument>);

    return (
        <>
            <FormContent
                form={form}
                title={
                    "Documento para [" +
                    (person ? person.surnames + ", " + person.name : "") +
                    "]"
                }>

                <Grid item sm>
                    <FormInput field={"title"} type={"text"} label={"Título"}/>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item sm={3}>
                        <FormSelect field={"type"} type={types.personDocumentTypes} label={"Tipo"}/>
                    </Grid>
                    <Grid item sm={3}>
                        <FormInput field={"expiration"} type={"date"} label={"Caducidad"}/>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item sm={3}>
                        <FormInput field={"year"} type={"number"} label={"Año"}/>
                    </Grid>
                    <Grid item sm={3}>
                        <FormInput field={"month"} type={"number"} label={"Mes"}/>
                    </Grid>
                    <Grid item sm={3}>
                        <FormToggle field={"visible"} label={"Visible por el empleado"}/>
                    </Grid>
                </Grid>

                <FormInput field={"comments"} type={"text"} label={"Comentarios"} rows={5}/>

                <FormFile
                    field={"attachment"}
                    label={"Archivo"}
                    onDownload={() =>
                        apiManager.executeRaw(ctrl.viewPersonDocument(form.getIdOrErr()))
                    }
                />

            </FormContent>
        </>
    );
};