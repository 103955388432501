import {Budget, BudgetRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {useApiManager} from "../../../../components/utils/api";
import {Grid, useTheme} from "@mui/material";
import {COLOR_DISMISSED} from "../../../../components/layout/colors";


export const BudgetListPage = () => {
	const apiManager = useApiManager();
	const ctrl = apiManager.factory(BudgetRestControllerApiFactory);
	const theme = useTheme();

	const getBudgetColor = (budget: Budget): string => {
		if (budget.archived || budget.dismissed){
			return COLOR_DISMISSED;
		}
		if (budget.accepted){
			return "inherit";
		}
		return theme.palette.warning.main;
	}

	const listConfig: AppListConfig<Budget> = {
		title: "Listado de Presupuestos",
		onClick: "/app/admin/billing/budget",
		decorator: (bean: Budget) => (
			<Grid container style={{color: bean.dismissed || bean.archived ? COLOR_DISMISSED : ""}}>
				<Grid item sm={3}>
					({bean.customer.id}) {bean.customer.shortName || "---"}
				</Grid>
				<Grid item sm={4} style={{color: getBudgetColor(bean)}}>
					({bean.id}) {bean.title}
				</Grid>
				<Grid item sm={4}>
					{bean.owner?.surnamesAndName}
				</Grid>
				<Grid item sm={1} style={{textAlign:"right"}}>
					{bean.totalHours || "---"}
				</Grid>

			</Grid>
		),
		supplier: async (currentPage, size, term, showDismissed) => {
			return ctrl.pageBudget(
				currentPage,
				size,
				undefined,
				term,
				showDismissed
			);
		}
	};

	return (
		<>
			<AppList config={listConfig}/>
		</>
	);
};
