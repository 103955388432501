import React, {useState} from "react";
import {DedicationImportDto, DedicationRestControllerApiFactory} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {Identifiable, useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import FormFile from "../../../../components/form/form_inputs/form_file";

export const RedmineImportPage = () => {
	const manager = useApiManager();
	const ctrl = manager.factory(DedicationRestControllerApiFactory);
	

	const [error, setError] = useState<string | undefined>(undefined)


	interface DedicationImportExtDto extends Identifiable, DedicationImportDto {
	}

	const factory = () => {
		return {
			id: undefined,
		} as DedicationImportExtDto;
	};

	const form = useForm({
		fetcher: (id) => factory(),
		updater: (bean) => {
			setError(undefined);
			const result = ctrl.importFileDedication(bean);
			result.catch(error => setError(JSON.stringify(error)));
			return result;
		},
		remover: (id) => {
		},
		factory: () => factory(),
	} as UseFormProps<DedicationImportExtDto>);

	return (
		<>
			<FormContent form={form} title={"Formulario de Importación"} hideTopButtons>

				<FormFile field={"attachment"} label={"Archivo"}/>

				<p>
					Asegura de <a target={"_blank"} href={"https://redmine.artecoapps.com/settings?tab=notifications"}>
					desactivar las notificaciones
				</a> de correo para no inundar el email.
				</p>

				{error && (
					<div style={{color: "red", marginTop:"1em"}}>{error}</div>
				)}

			</FormContent>
		</>
	);
};
