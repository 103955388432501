import {
	Alert,
	Button,
	CircularProgress,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useApiManager} from "../../../../components/utils/api";
import {
	DedicationBillingSummary,
	DedicationImportSummary,
	DedicationRestControllerApiFactory,
	Occupation
} from "arteco-api-client-ts";
import {fileLocalSave} from "../../../../components/form/form_inputs/form_file";
import DedicationTable from "./dedication/dedication_table";
import AlertTitle from "@mui/material/AlertTitle";
import {BudgetSummaries} from "./budget_summaries";
import {Box} from "@mui/system";


export interface OccupationGroupProps {
	year: number;
	month: string;
	occupation: Occupation;
}


export const OccupationGroup = ({month, year, occupation}: OccupationGroupProps) => {

	const apiManager = useApiManager();
	const dedicationCtrl = apiManager.factory(DedicationRestControllerApiFactory);

	const [operatingBills, setOperatingBills] = useState<boolean>(false);
	const [summary, setSummary] = useState<DedicationBillingSummary | undefined>(undefined);
	const [selectedDedicationIds, setSelectedDedicationIds] = useState<number[]>([]);
	const [redmineImporting, setRedmineImporting] = useState<boolean>(false);
	const [importSummary, setImportSummary] = useState<DedicationImportSummary | undefined>(undefined);
	const dedicable = {selectedDedicationIds, setSelectedDedicationIds};

	const loadBilledPending = () => {
		apiManager.execute(dedicationCtrl.checkBillingPendingDedication(year, parseInt(month)))
		.then(summary => setSummary(summary));
	}

	useEffect(() => {
		loadBilledPending();
	}, [month]);

	const borrarFacturas = () => {
		setOperatingBills(true);
		if (confirm("Seguro que desea borrar las facturas de las dedicaciones?")) {
			apiManager.execute(dedicationCtrl.deleteBillsDedication(year, parseInt(month)))
			.then(bills => {
				loadBilledPending();
				alert("Se han borrado " + bills.length + " facturas");
				setOperatingBills(false);
			});
		} else {
			setOperatingBills(false);
		}
	}

	const crearFacturas = () => {
		setOperatingBills(true);
		apiManager.execute(dedicationCtrl.makeBillsDedication(year, parseInt(month), selectedDedicationIds))
		.then(bills => {
			loadBilledPending();
			alert("Se han generado " + bills.length + " facturas");
			setOperatingBills(false);
		});
	}

	const duplicarDedicaciones = (year: number, month: number) => {
		apiManager.execute(dedicationCtrl.duplicateDedication(year, month, selectedDedicationIds))
		.then(bills => {
			alert("Operación realizada")
		});
	}


	const pdfFacturas = () => {
		setOperatingBills(true);
		apiManager.executeRaw(dedicationCtrl.pdfsDedication(year, parseInt(month)))
		.then((resp) => {
			fileLocalSave(resp);
			setOperatingBills(false);
		});
	}

	return (
		<>
			<BudgetSummaries year={year} month={month}/>

			<Grid container spacing={3} mt={1} alignContent={"stretch"}>
				<Grid item sm={6}>


					<Alert color={summary?.billsGenerated ? "error" : undefined} sx={{height: "100%"}}>
						<AlertTitle>Facturas</AlertTitle>

						{summary && summary.billingPending && summary.billingPending > 0 &&
							<Button variant="contained" disabled={operatingBills} sx={{marginRight: "1em"}}
									onClick={() => {
										if (confirm("Desea generar facturas según Cliente y Grupo Factura?")) {
											crearFacturas();
										}
									}}>
								Facturar
								({selectedDedicationIds.length > 0 ? selectedDedicationIds.length : summary.billingPending})
								dedicaciones
							</Button>
							|| ""}

						{summary && summary.billsGenerated && summary.billsGenerated > 0 && (
							<>
								<Button variant="contained" disabled={operatingBills} sx={{marginRight: "1em"}}
										onClick={() => borrarFacturas()}>
									Borrar {summary.billsGenerated} Facturas
								</Button>
								<Button variant="contained" disabled={operatingBills} onClick={() => pdfFacturas()}>
									PDFs
								</Button>
							</>
						) || ""}

					</Alert>
				</Grid>
				<Grid item sm={2}>
					<Alert color={"success"} sx={{height: "100%"}}>
						<AlertTitle>Herramientas</AlertTitle>
						<Button variant={"contained"}
								size={"small"}
								disabled={selectedDedicationIds.length < 1}
								onClick={_ => {
									const value = prompt("A qué año y mes (yyyy-mm) desea duplicar ?");
									const regex = /^\d{4}-(0[1-9]|1[0-2])$/;
									if (value && regex.test(value)) {
										const partes = value.split('-');
										const nyear = parseInt(partes[0], 10);
										const nmonth = parseInt(partes[1], 10);
										duplicarDedicaciones(nyear, nmonth);
									} else {
										alert("Fecha incorrecta");
									}
								}}>
							Duplicar
						</Button>
						<Button variant={"contained"}
								size={"small"}
								sx={{marginRight: "1em", marginTop: "1em"}}
								disabled={selectedDedicationIds.length < 1}
								onClick={_ => {
									if (confirm("Seguro que desea borrar todas las seleccionadas?")) {
										dedicationCtrl
										.dedicationBatchDeleteDedication(selectedDedicationIds)
										.then(resp => {
											alert(resp.data);
										})
									}
								}}>
							Borrar selección
						</Button>
						<Button variant={"contained"}
								size={"small"}
								disabled={redmineImporting}
								sx={{marginRight: "1em", marginTop: "1em"}}
								onClick={_ => {
									if (confirm("Seguro que desea importar las dedicaciones de Redmine?")) {
										setRedmineImporting(true);
										apiManager.execute(dedicationCtrl
										.importAllFromRedmineDedication(year, parseInt(month)))
										.then(resp => {
											setImportSummary(resp);
											setRedmineImporting(false);
										}, err => {
											alert("Se ha producido un error al importar las dedicaciones")
											setRedmineImporting(false);
										});
									}
								}}>
							{redmineImporting && (<CircularProgress size="1rem" />)}
							Importar de redmine
						</Button>
					</Alert>
				</Grid>
				<Grid item sm={4}>
					<Alert color={"warning"} sx={{height: "100%"}}>
						El rol sólo aparece en las empresas con <strong>facturas anónimas</strong> de la siguiente
						manera:<br/>
						<code>P-3232 - &lt;rol_persona&gt;</code><br/>
					</Alert>
				</Grid>
			</Grid>


			<DedicationTable month={month}
							 year={year}
							 showPerson={true}
							 personId={undefined}
							 occupation={occupation}
							 dedicable={dedicable}/>


			{importSummary && (

				<Box sx={{position: "absolute", background: "white", padding: 4, top: 10, left: 10, right: 10, border: 1}}>
					<button onClick={_ => setImportSummary(undefined)}>cerrar</button>

					<TableContainer sx={{my:1}}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell sx={{color: "white"}}>ID Proyecto</TableCell>
									<TableCell sx={{color: "white"}}>Nombre del Proyecto</TableCell>
									<TableCell sx={{color: "white"}}>Presupuesto</TableCell>
									<TableCell sx={{color: "white"}}>Horas Trabajadas</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{importSummary.budgets?.map((row, index) => (
									<TableRow key={index}
											  sx={{backgroundColor: (row.workedHours || 0) > 30 ? "#f4f4f4" : "inherit"}}>
										<TableCell>{row.projectId}</TableCell>
										<TableCell>{row.projectName}</TableCell>
										<TableCell>{row.budgetName || "N/A"}</TableCell>
										<TableCell>{row.workedHours}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					{importSummary.warnings?.map((warning, index) => (
						<Alert sx={{my:1}} key={"w-"+index} color={"warning"}>{warning}</Alert>
					))}

				</Box>

			)}
		</>
	)
}

