import React, {useEffect, useState} from "react";

import {useApiManager} from "../../../../components/utils/api";

import {
    CandidateSource,
    CandidateSourceRestControllerApiFactory,
    Recruitment,
    RecruitmentRestControllerApiFactory,
} from "arteco-api-client-ts";

import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import FormAutocomplete from "../../../../components/form/form_inputs/form_autocomplete";
import {types} from "../../../../components/form/types";
import FormSelect from "../../../../components/form/form_inputs/form_select";
import {Box, Grid, Typography} from "@mui/material";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useNavigate} from "react-router-dom";
import {H4, Typographies} from "../../../../components/utils/typography_consts";
import FormToggle from "../../../../components/form/form_inputs/form_toggle";

export const RecruitmentEditPage = () => {
    const navigate = useNavigate();
    const apiManager = useApiManager();

    const [sources, setSources] = useState<CandidateSource[]>();

    const ctrl = apiManager.factory(RecruitmentRestControllerApiFactory);
    const sourceCtrl = apiManager.factory(CandidateSourceRestControllerApiFactory);
    

    const recruitForm = useForm({
        fetcher: (id: number) => ctrl.getRecruitment(id),
        updater: (bean: Recruitment) => ctrl.saveRecruitment(bean),
        remover: (id) => ctrl.deleteOrDismissRecruitment(id),
        factory: () => {
            return {} as Recruitment;
        },
        extraActions: [
            {
                label: "Inscripciones",
                action: () => {
                    navigate("/app/admin/recruitment/recruitment/" + recruitForm.getIdOrErr() + "/application");
                },
            },
            {
                label: "Archivos",
                action: () => {
                    navigate("/app/admin/recruitment/recruitment/" + recruitForm.getIdOrErr() + "/document");
                },
            },
            {
                label: "Wiki",
                color: "info",
                action: () => {
                    window.open("https://redmine.artecoapps.com/projects/p99-arteco-expansion/wiki", '_blank');
                }
            }
        ],
        idParamName: "recruitmentId"
    } as UseFormProps<Recruitment>);

    useEffect(() => {
        apiManager.executeRaw<CandidateSource[]>(sourceCtrl.listCandidateSource())
            .then((resp) => setSources(resp.data));
    }, []);

    return (
        <>
            <FormContent form={recruitForm} title={"Ficha del proceso"}>
                <Grid container spacing={3}>
                    <Grid item md>

                        <Grid container spacing={3}>
                            <Grid item sm={6}>
                                <Grid container spacing={2}>
                                    <Grid item sm={2}>
                                        <FormToggle field={"visible"} label={"Visible"}/>
                                    </Grid>
                                    <Grid item sm>
                                        <FormInput type={"text"} field={"title"} label={"Título"}/>
                                    </Grid>
                                </Grid>
                                <FormInput type={"text"} field={"publicTitle"} label={"Título público"}/>
                                <FormInput type={"text"} field={"linkedinUrl"} label={"Vacante en linkedin"}
                                           placeholder={"https://www.linkedin.com/jobs/..."}/>
                            </Grid>
                            <Grid item sm sx={{paddingBottom: "2em"}}>
                                <Typography variant={Typographies.h4} component={H4}>
                                    Enlaces públicos
                                </Typography>
                                <p>
                                    Formulario amplio:
                                </p>
                                {sources &&
                                    sources.map((elem, indx) => (
                                        <Box mr={1} key={indx} component={"span"}>
                                            <a
                                                href={
                                                    "/candidate?rid=" +
                                                    recruitForm.getId() +
                                                    "&s=" + elem.value
                                                }
                                                target={"_blank"}
                                                rel="noreferrer">
                                                {elem.label}
                                            </a>
                                        </Box>
                                    ))}
                                <p>
                                    Formulario corto:
                                </p>
                                {sources &&
                                    sources.map((elem, indx) => (
                                        <Box mr={1} key={indx} component={"span"}>
                                            <a
                                                href={
                                                    "/candidate?m=s&rid=" +
                                                    recruitForm.getId() +
                                                    "&s=" + elem.value
                                                }
                                                target={"_blank"}
                                                rel="noreferrer">
                                                {elem.label}
                                            </a>
                                        </Box>
                                    ))}
                            </Grid>
                        </Grid>


                        <Grid container spacing={3}>
                            <Grid item sm>
                                <FormAutocomplete
                                    type={types.customers}
                                    field={"customer"}
                                    label={"Cliente"}
                                />
                            </Grid>
                            <Grid item sm>
                                <FormSelect
                                    type={types.recruitmentStatus}
                                    field={"status"}
                                    label={"Estado"}
                                />
                            </Grid>
                        </Grid>

                        <FormInput rows={20} field={"description"} label={"Descripción pública"}/>
                        <FormInput
                            type={"text"}
                            field={"comments"}
                            label={"Comentarios privados"}
                            placeholder={"Indicar información del estado, consideraciones y vías de publicación utilizadas"}
                            rows={15}
                        />
                    </Grid>
                </Grid>
            </FormContent>
        </>
    );
};