import React, {useContext, useEffect, useState} from "react";
import {FormContext} from "../form_buttons";
import {Attachment} from "arteco-api-client-ts";
import {Box, Button} from "@mui/material";
import {AxiosPromise, AxiosResponse} from "axios";
import {OptionsObject, SnackbarMessage, useSnackbar} from "notistack";
import {useApiManager} from "../../../utils/api";
import {fieldMarginBottom} from "../../form_constants";

interface FormFileProp {
	field: string;
	label: string;
	className?: string;
	onDownload?: () => AxiosPromise<string>;
	onChange?: (attach: Attachment | undefined) => void;
	helperText?: string;
	hideName?: boolean;
	hideShow?: boolean;
	enablePaste?: boolean;
}

export const fileToAttach = (
	file: File,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => void
): Promise<Attachment> => {
	return new Promise<Attachment>((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.onload = (event) => {
			const arr: ArrayBuffer = event.target
				? (event.target.result as ArrayBuffer)
				: new ArrayBuffer(0);
			const bytes = Array.from(new Uint8Array(arr));
			const base64StringFile = btoa(bytes.map((item) => String.fromCharCode(item)).join(""));
			const attach = {
				filename: file.name,
				mime: file.type,
				content: base64StringFile as any as string,
				length: bytes.length
			} as Attachment;
			return resolve(attach);
		};
		fileReader.onerror = () => {
			reject();
			enqueueSnackbar("No se ha podido leer el archivo ", {variant: "error"});
		};
		fileReader.readAsArrayBuffer(file);
	});
};

export const fileBlobFromAttachment = (attachment: Attachment) => {
	if (!attachment.mime || !attachment.filename || !attachment.content) {
		throw new Error("No attachment passed");
	}
	const b64Data = attachment.content as any as string;
	return fileBlobFromStrings(b64Data, attachment.mime, attachment.filename);
}

export const fileBlobFromStrings = (b64Data: string, mime: string, filename: string) => {
	const byteCharacters = atob(b64Data);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	const blob = new Blob([byteArray], {type: mime});
	const url = window.URL.createObjectURL(blob);
	return {filename, url};
}

export const fileBlobFromAxios = (resp: AxiosResponse<string>) => {
	const b64Data = resp.data as any as string;
	const mime = resp.headers["content-type"];
	const filename = resp.headers["x-filename"];
	return fileBlobFromStrings(b64Data, mime, filename);
}

export const fileLocalSave = (resp: AxiosResponse<string>, forcedFilename?: string) => {
	const {filename, url} = fileBlobFromAxios(resp);
	const a = document.createElement("a");

	a.style.display = "none";
	a.href = url;
	const lastPoint = filename.lastIndexOf(".");
	const ext = filename.substring(lastPoint);
	a.download = forcedFilename ? forcedFilename + ext : filename;
	document.body.appendChild(a);
	a.click();
	window.URL.revokeObjectURL(url);
};

export const formatLength = (attachment: Attachment | undefined): string => {
	if (attachment) return ((attachment.length || 0) / 1024).toFixed(1) + "Kb";
	return "0 Kb";
};

const FormFile = (props: FormFileProp) => {
	const {field, label, onDownload, onChange, hideName, hideShow, enablePaste} = props;
	const apiManager = useApiManager();
	const {enqueueSnackbar} = useSnackbar();

	const formContext = useContext(FormContext);

	const model = formContext.form;
	const modelField = model.field(field);

	const [attachment, setAttachment] = useState<Attachment | undefined>(modelField.value());
	model.register(field, () => attachment, t => setAttachment(t));

	const changeAttachment = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file: File | undefined =
			event.target && event.target.files ? event.target.files[0] : undefined;
		if (file) {
			fileToAttach(file, enqueueSnackbar).then((attach) => {
				setAttachment(attach);
				if (onChange) {
					onChange(attach);
				}
			});
		}
	};

	const removeAttachment = () => {
		setAttachment(undefined);
		if (onChange) {
			onChange(undefined);
		}
	};

	const downloadFile = () => {
		if (onDownload) {
			apiManager
			.executeRaw(onDownload())
			.then((resp) => fileLocalSave(resp));
		} else {
			console.error("No download method");
		}
	};

	const showFile = () => {
		if (onDownload) {
			apiManager
			.executeRaw(onDownload())
			.then((resp) => {
				const {url} = fileBlobFromAxios(resp);
				window.open(url);
			});
		} else {
			console.error("No download method");
		}
	};

	if (enablePaste) {
		const storeImage = (e: any) => {
			const files = e.clipboardData.files;
			for (let i = 0; i < files.length; i++) {
				let file = files.item(i);
				if (file.type.indexOf("image") == -1) continue;
				fileToAttach(file, apiManager.enqueueSnackbar).then((attach) => {
					setAttachment(attach);
					if (onChange) {
						onChange(attach);
					}
				});
			}
		};
		useEffect(() => {
			window.addEventListener("paste", storeImage);
			return () => window.removeEventListener("paste", storeImage);
		}, []);
	}


	return (
		<Box
			className={[
				"form-input",
				"form-input-" + field,
				props.className || "",
			].join(" ")} mb={fieldMarginBottom}>

			<Button variant="contained" component="label" sx={{backgroundColor: "rgb(180 194 214)", mr: 2}}>
				{label}
				<input
					hidden
					id={field}
					type="file"
					onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
						const element = e.target as HTMLInputElement;
						element.value = "";
					}}
					onChange={(event) => changeAttachment(event)}
				/>
			</Button>

			{attachment && attachment.filename && (
				<>
					{!hideName &&
						<>
							{onDownload && (
								<Box sx={{mr: 2}} component={"span"}>
									<a href={"#"} onClick={(e) => {
										e.preventDefault();
										downloadFile();
									}}>
										{attachment.filename}
									</a>
									&nbsp;({formatLength(attachment)})
								</Box>
							)}
							{!onDownload && <span>{attachment.filename} ({formatLength(attachment)})</span>}
						</>
					}

					{!hideShow &&
						<>
							{onDownload && attachment && attachment.mime &&
								(attachment.mime.indexOf("image") > -1 || attachment.mime.indexOf("pdf")) && (

									<Box sx={{mr: 2}} component={"span"}>
										<a href={"#"} onClick={(e) => {
											e.preventDefault();
											showFile();
										}}>ver</a>
									</Box>

								)}
						</>
					}


					<Box sx={{mr: 2}} component={"span"}>
						<a href={"#"} onClick={(e) => {
							e.preventDefault();
							removeAttachment();
						}}>quitar</a>
					</Box>

				</>
			)}

			{props.helperText && (

				<Box mt={1}><small>{props.helperText}</small></Box>
			)}

		</Box>
	);
};

export default FormFile;
