import {WorkBooking, WorkBookingRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {useApiManager} from "../../../../components/utils/api";
import {Grid} from "@mui/material";
import {dateToString} from "../../../../components/utils/dates";

export const WorkBookingListPage = () => {
    const apiManager = useApiManager();

    const listConfig: AppListConfig<WorkBooking> = {
        title: "Reservas de plazas",
        onClick: "/app/admin/employee/workbooking",
        decorator: (bean: WorkBooking) =><Grid container >
            <Grid item sm>{dateToString(bean.bookingDate)}</Grid>
            <Grid item sm>{bean.workCenter.name}</Grid>
            <Grid item sm>{bean.person.surnamesAndName}</Grid>
            <Grid item sm>{bean.places}</Grid>
        </Grid> ,
        supplier: async (currentPage, size, term, showDismissed) => {
            
            const ctrl = apiManager.factory(WorkBookingRestControllerApiFactory);
            return ctrl.pageWorkBooking(currentPage, size, undefined, undefined, undefined,
                undefined, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};