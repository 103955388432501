import React from "react";
import {Template, TemplateRestControllerApiFactory} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Grid} from "@mui/material";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";

export const TemplateEditPage = () => {
    const manager = useApiManager();
    const ctrl = manager.factory(TemplateRestControllerApiFactory);
    

    const form = useForm({
        fetcher: (id) => ctrl.getTemplate(id),
        updater: (bean) => ctrl.saveTemplate(bean),
        remover: (id) => ctrl.deleteOrDismissTemplate(id),
        factory: () => {
            return {  } as Template;
        },
    } as UseFormProps<Template>);

    return (
        <>
            <FormContent form={form} title={"Ficha de plantilla"}>
                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput type={"text"} field={"name"} label={"Nombre"} />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput type={"text"} field={"html"} label={"Html"} rows={30} />
                    </Grid>
                </Grid>
            </FormContent>
        </>
    );
};