import {Box} from "@mui/material";
import React from "react";
import {Occupation} from "arteco-api-client-ts";


export interface CalendarProps {
	occupation: Occupation;
	personId: number;
	month: string;
}

export const Occupation_calendar = ({occupation, month, personId}: CalendarProps) => {

	const calendar = occupation.calendarByMonthAndPersonId
		? occupation.calendarByMonthAndPersonId[month][personId]
		: [];

	return (
		<>
			<table style={{width: "100%"}}>
				<thead>
				<tr>
					<th align={"left"}>FECHA</th>
					<th align={"left"}>DÍA</th>
					<th align={"left"}>TRABAJO</th>
				</tr>
				</thead>
				<tbody>
				{calendar.map((row, rowIdx) => (
					<tr key={rowIdx}
						style={{color: row.weekend || row.holiday ? "red" : ""}}>
						<td style={{paddingRight: "1em"}}>
							{row.date}
						</td>
						<td style={{paddingRight: "2em"}}>
							{row.weekday}
						</td>
						<td>
							{row.holiday && (
								<Box color={"red"}>
									{row.holiday}
								</Box>
							)}
							{!row.holiday && (
								<>
									{!row.availableHours && row.weekend && !row.vacation && (
										<span>---</span>
									)}

									{!row.vacation && !row.weekend && (
										<>{row.availableHours}</>
									)}
									{row.vacation && (
										<Box color={
											row.vacationState ==
											"ACCEPTED"
												? "primary.main"
												: "text.disabled"
										}>
											{row.vacationType as string}
										</Box>
									)}

								</>
							)}

						</td>
					</tr>
				))}
				</tbody>
			</table>
		</>
	)
}