import {Candidate, CandidateRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {useApiManager} from "../../../../components/utils/api";
import {Grid} from "@mui/material";
import {COLOR_DISMISSED} from "../../../../components/layout/colors";
import {fileLocalSave} from "../../../../components/form/form_inputs/form_file";
import React, {useEffect, useState} from "react";

export const useCandidate = (id: number| undefined): [(Candidate | undefined), React.Dispatch<React.SetStateAction<Candidate | undefined>>] => {
    const apiManager = useApiManager();
    const [getter, setter] = useState<Candidate|undefined>();
    const ctrl = apiManager.factory(CandidateRestControllerApiFactory);

    useEffect(()=>{
        if (id){
            apiManager
                .execute(ctrl.getCandidate(id))
                .then(c => setter(c));

        }
    },[id]);
    return [getter, setter];
};

export const CandidateListPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(CandidateRestControllerApiFactory);
    

    const listConfig: AppListConfig<Candidate> = {
        title: "Listado de candidatos ",
        onClick: "/app/admin/recruitment/candidate",
        decorator: (bean: Candidate) => (
            <Grid
                container
                style={{ color: bean.dismissed ? COLOR_DISMISSED : "inherit" }}>
                <Grid item sm>
                    {bean.surnames + ", " + bean.name}
                </Grid>
                <Grid item sm>
                    {bean.type?.name}
                </Grid>
                <Grid item sm>
                    <small>{bean.state?.name}</small>
                </Grid>
                <Grid item sm>
                    <small>{bean.points}</small>
                </Grid>
                <Grid item sm>
                    <small>{bean.source?.label || ""}</small>
                </Grid>
                <Grid item sm>
                    <small>{bean.reqAnnualPayroll || ""}</small>
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            
            return ctrl.pageCandidate(currentPage, size, undefined, term, showDismissed);
        },
        actions: [
            {
                action: () => {
                    apiManager.executeRaw(ctrl.exportCandidate()).then((resp) => {
                        fileLocalSave(resp);
                    });
                },
                label: "Exportar"
            },
        ]
    };

    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};