import {TrainingLevel, TrainingLevelRestControllerApiFactory,} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {COLOR_GRIS} from "../../../../components/layout/colors";
import {Grid} from "@mui/material";
import {useApiManager} from "../../../../components/utils/api";

export const TrainingLevelListPage = () => {
    const apiManager = useApiManager();

    const listConfig: AppListConfig<TrainingLevel> = {
        title: "Listado de formaciones ",
        onClick: "/app/admin/recruitment/traininglevel",
        decorator: (bean: TrainingLevel) => (
            <Grid container style={{ color: bean.dismissed ? COLOR_GRIS : "" }}>
                <Grid item sm>
                    {bean.label}
                </Grid>
                <Grid item sm>
                    {bean.level}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            
            const ctrl = apiManager.factory(TrainingLevelRestControllerApiFactory);
            return ctrl.pageTrainingLevel(currentPage, size, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};