import {Customer, CustomerRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {COLOR_DISMISSED} from "../../../../components/layout/colors";
import {Grid} from "@mui/material";
import {useApiManager} from "../../../../components/utils/api";

export const CustomerListPage = () => {
    const apiManager = useApiManager();

    const listConfig: AppListConfig<Customer> = {
        title: "Listado de Clientes ",
        onClick: "/app/admin/billing/customer",
        decorator: (bean: Customer) => (
            <Grid container style={{ color: bean.dismissed ? COLOR_DISMISSED : "" }}>
                <Grid item sm={2}>
                    {bean.shortName}
                </Grid>
                <Grid item sm={4}>
                    {bean.name}
                </Grid>
                <Grid item sm>
                    {bean.discount?.name}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            const ctrl = apiManager.factory(CustomerRestControllerApiFactory);
            return ctrl.pageCustomer(
                currentPage,
                size,
                undefined,
                term,
                showDismissed
            );
        },
    };

    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};