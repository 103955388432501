import {OptionsObject, SnackbarMessage, useSnackbar} from "notistack";
import {type AxiosInstance, AxiosPromise} from "axios";
import {Configuration} from "arteco-api-client-ts";
import {useAuthManager} from "./auth";
import {envVars} from "./env_vars";
import axios from 'axios';

const axiosInstance = axios.create({
	// evita un undefined en el uso de los clientes
});

export interface ApiManager {
	basePath: string;
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => void;

	/**
	 * Envuelve la promesa de Axios con la gestión de errores para mostrar un snackbar
	 * con resultado ok o el error proveniente del api. Devolviendo un Promise estándar (no Axios)
	 * @param axiosPromise
	 * @param okMessage
	 */
	execute<T>(axiosPromise: AxiosPromise<T>, okMessage?: string): Promise<T>;

	/**
	 * Similar a execute, pero devuelve la promise de Axios, envuelta con mensajes de snackbar.
	 * Es preferible usar execute, para no propagar la dependencia de Axios por el proyecto.
	 * @param axiosPromise
	 * @param okMessage
	 */
	executeRaw<T>(axiosPromise: AxiosPromise<T>, okMessage?: string): AxiosPromise<T>;

	/**
	 * Construye una nueva instancia de un controlador generado por OpenApi dado un xxxApiFactory
	 * @param ctrlApiFactor el factory a usar. Ej: UserRestControllerApiFactory
	 */
	factory<T>(ctrlApiFactor: (config: Configuration) => T): T;

	/**
	 * Construye una nueva instancia de un controlador generado por OpenApi dado un xxxApiFactory
	 * @param ctrlApiFactor el factory a usar. Ej: UserRestControllerApiFactory
	 * @param username usuario
	 * @param password clave
	 */
	factoryRaw<T>(ctrlApiFactor: (config: Configuration) => T, username: string, password: string): T;
}

export function useApiManager(): ApiManager {
	const basePath = envVars.apiBasePath;
	const {enqueueSnackbar} = useSnackbar();
	const authManager = useAuthManager();
	const password = authManager.getPassword();

	return {
		executeRaw<T>(axiosPromise: AxiosPromise<T>, okMessage?: string): AxiosPromise<T> {
			axiosPromise
			.then((resp) => {
				if (okMessage) {
					enqueueSnackbar(okMessage);
				}
				return resp;
			})
			.catch((error) => {
				let cause = "";
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.error
				) {
					cause = "Causa = [" + error.response.data.error + "]";
				}
				enqueueSnackbar("La petición no se ha realizado. " + cause, {
					variant: "error",
				});
				return error;
			});
			return axiosPromise;
		},

		execute<T>(axiosPromise: AxiosPromise<T>, okMessage?: string): Promise<T> {
			const rawPromise = this.executeRaw(axiosPromise, okMessage);
			return new Promise<T>((resolve, reject) => {
				return rawPromise.then((resp) => {
					resolve(resp.data);
				}, reject);
			});
		},
		factory<T>(ctrlApiFactor: (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) => T): T {
			const config = new Configuration({
				basePath: basePath,
				username: authManager.getUsername(),
				password: password
			});
			return ctrlApiFactor(config, basePath, axiosInstance);
		},
		factoryRaw<T>(ctrlApiFactor: (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) => T, username: string, password: string): T {
			const config = new Configuration({
				basePath: basePath,
				username: username,
				password: password
			});
			return ctrlApiFactor(config, basePath, axiosInstance);
		},
		enqueueSnackbar: enqueueSnackbar,
		basePath: basePath,
	} as ApiManager;
}
