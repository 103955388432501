import React from "react";

import {Budget, BudgetRestControllerApiFactory,} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Grid, TextField} from "@mui/material";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import FormAutocomplete from "../../../../components/form/form_inputs/form_autocomplete";
import {types} from "../../../../components/form/types";
import {useNavigate} from "react-router-dom";
import FormToggle from "../../../../components/form/form_inputs/form_toggle";
import {useCustomerNotes} from "../bill/form";

export const BudgetEditPage = () => {
	const apiManager = useApiManager();
	const navigate = useNavigate();

	const ctrl = apiManager.factory(BudgetRestControllerApiFactory);
	const {customerNotes, setCustomerId} = useCustomerNotes();


	const form = useForm({
		fetcher: (id) => {
			const res = ctrl.getBudget(id)
			res.then(d => setCustomerId(d.data.customer.id))
			return res;
		},
		updater: (bean) => ctrl.saveBudget(bean),
		remover: (id) => ctrl.deleteOrDismissBudget(id),
		factory: () => {
			return {} as Budget;
		},
		extraActions: [
			{
				label: "Facturas",
				action: () => {
					navigate("/app/admin/billing/bill?budgetId=" + form.getId());
				},
			},
		]
	} as UseFormProps<Budget>);


	return (
		<>
			<FormContent form={form} title={"Formulario de presupuesto"}>

				<Grid container spacing={2}>
					<Grid item sm={3}>
						<FormAutocomplete field={"customer"} label={"Cliente"} type={types.customers}
										  onChange={(t, c) => setCustomerId(c?.id)}/>
					</Grid>
					<Grid item sm={6}>
						<FormInput field={"title"} label={"Título"}/>
					</Grid>
					<Grid item sm={3}>
						<FormToggle field={"archived"} label={"Archivado"}/>
					</Grid>
				</Grid>

				<Grid container spacing={2}>
					<Grid item sm={3}>
						<FormAutocomplete field={"owner"} label={"Asignado"} type={types.people}/>
					</Grid>
					<Grid item sm={3}>
						<FormInput field={"totalHours"} type={"number"} label={"Total horas"}
								   helperText={"Si es 0 se considera T&M"}/>
					</Grid>
					<Grid item sm={3}>
						<FormInput field={"amount"} type={"number"} label={"Importe SIN Iva"}
								   helperText={"Si es 0 se considera T&M"}/>
					</Grid>
					<Grid item sm={3}>
						<FormToggle field={"accepted"} label={"Aceptado"}/>
					</Grid>
				</Grid>

				<FormInput field={"analysisLocation"}
						   label={"Ubicación del análisis"}
						   helperText={"Indicar la ubicación del documento con el análisis"}
						   rows={1}/>

				<FormInput field={"customerAcceptance"}
						   label={"Ubicación de la aceptación del cliente"}
						   helperText={"Debe estar archivado en Drive o en el correo. Indicar cómo localizarlo"}
						   rows={2}/>

				<FormInput field={"description"} label={"Description"} rows={5}/>
				<FormInput field={"observation"} label={"Observación (privada)"} rows={5}/>

				<TextField
					sx={{mt: 2}}
					fullWidth
					label={"Notas del cliente"}
					value={customerNotes}
					disabled
					minRows={5}
					multiline={true}
					inputProps={{style: {fontSize: "0.8em"}}}/>

			</FormContent>
		</>
	);
};