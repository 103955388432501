import {WorkCenter, WorkCenterRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {useApiManager} from "../../../../components/utils/api";
import {Grid} from "@mui/material";

export const WorkCenterListPage = () => {
    const apiManager = useApiManager();

    const listConfig: AppListConfig<WorkCenter> = {
        title: "Centros de Trabajo ",
        onClick: "/app/admin/employee/workcenter",
        decorator: (bean: WorkCenter) =><Grid container >
            <Grid item sm>{bean.name}</Grid>
            <Grid item sm>{bean.address}</Grid>
            <Grid item sm>{bean.city}</Grid>
            <Grid item sm>{bean.calendar}</Grid>
            <Grid item sm>{bean.disabled ? "Desactivada" : ""}</Grid>
        </Grid> ,
        supplier: async (currentPage, size, term, showDismissed) => {
            
            const ctrl = apiManager.factory(WorkCenterRestControllerApiFactory);
            return ctrl.pageWorkCenter(currentPage, size, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};