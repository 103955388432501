import AppList, {AppListConfig} from "../../../../../components/layout/list";
import {Salary, SalaryRestControllerApiFactory,} from "arteco-api-client-ts";
import React from "react";
import {useApiManager} from "../../../../../components/utils/api";
import {useParams} from "react-router-dom";
import {usePerson} from "../index";
import {parsePathId} from "../../../../../components/form/form_utils";

export const SalaryListPage = () => {
	const params = useParams();
	const apiManager = useApiManager();
	

	const personId = parsePathId(params, "personId");
	const [person, setPerson] = usePerson(personId);

	const salaryCtrl = apiManager.factory(SalaryRestControllerApiFactory);


	const listConfig: AppListConfig<Salary> = {
		title: "Salarios de " + (person ? person.surnames + ", " + person.name : ""),
		onClick: "/app/admin/employee/person/" + person?.id + "/salary",
		decorator: (bean: Salary) =>
			bean.person.surnames +
			", " +
			bean.person.name +
			" / " +
			bean.startDate +
			" / " +
			bean.annualSalary +
			" / " +
			bean.avgHourRate +
			" / " +
			bean.role+
			" / " +
			bean.roleLevel,
		supplier: async (currentPage, size, term, showDismissed) => {
			if (personId) {
				return salaryCtrl.pageSalary(
					personId,
					currentPage,
					size,
					undefined,
					undefined,
					term,
					showDismissed
				);
			} else {
				throw new Error("PersonId is null");
			}
		},
	};

	return (
		<>
			<AppList config={listConfig}/>
		</>
	);
};