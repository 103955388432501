import React from "react";

import {PayrollPack, PayrollPackRestControllerApiFactory} from "arteco-api-client-ts";

import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Grid} from "@mui/material";
import FormSelect from "../../../../components/form/form_inputs/form_select";
import {types} from "../../../../components/form/types";
import FormFile from "../../../../components/form/form_inputs/form_file";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import {useLocation, useNavigate} from "react-router-dom";
import moment from "moment";


export const PayrollPackEditPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(PayrollPackRestControllerApiFactory);

    const form = useForm({
        fetcher: (id) => ctrl.getPayrollPack(id),
        updater: (bean) => ctrl.savePayrollPack(bean),
        remover: (id) => ctrl.deleteOrDismissPayrollPack(id),
        factory: () => {
            return {
                type: "PLA",
                periodFrom: moment()
                    .startOf('month')
                    .toISOString(),
                periodTo: moment()
                    .startOf('month')
                    .add(1, 'months')
                    .subtract(1, 'days')
                    .toISOString()
            } as PayrollPack;
        },
        extraActions: [
            {
                label: "Procesar",
                action: () => {
                    apiManager
                        .execute(ctrl.processPayrollPack(form.getIdOrErr()), "Pack procesado correctamente")
                        .then((resp) => {
                            form.propModel.set(resp);
                        });
                },
            },
        ],
    } as UseFormProps<PayrollPack>);

    return (
        <>
            <FormContent form={form} title={"Paquetes de nóminas"}>
                <Grid container spacing={3}>
                    <Grid item sm={3}>
                        <FormInput field={"periodFrom"} type={"date"} label={"Desde"}/>
                    </Grid>
                    <Grid item sm={3}>
                        <FormInput field={"periodTo"} type={"date"} label={"Hasta"}/>
                    </Grid>
                    <Grid item sm={3}>
                        <FormSelect field={"type"} label={"Tipo"} type={types.payrollPackType}/>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>

                    <Grid item sm={3}>
                        <FormInput field={"processed"} type={"date"} label={"Procesado"}/>
                    </Grid>
                    <Grid item sm={3}>
                        <FormInput
                            disabled
                            field={"companyCost"}
                            type={"number"}
                            label={"Coste Empresa"}
                        />
                    </Grid>
                </Grid>

                <FormFile
                    field={"costs"}
                    label={"Costes"}
                    onDownload={() => apiManager.executeRaw(ctrl.downloadCostsPayrollPack(form.getIdOrErr()))}
                />

                <FormFile
                    field={"payrolls"}
                    label={"Nóminas"}
                    onDownload={() => apiManager.executeRaw(ctrl.downloadPdfsPayrollPack(form.getIdOrErr()))}
                />

                <FormInput field={"observations"} type={"text"} label={"Observación"}/>
            </FormContent>
        </>
    );
};