import {Box, Button, Modal, Typography} from "@mui/material";
import {employeeModalStyle} from "./modal";
import {EmployeeRestControllerApiFactory, PersonReminderList} from "arteco-api-client-ts";
import {useEffect, useState} from "react";
import {useApiManager} from "../../components/utils/api";


export function EmpReminders() {
	const apiManager = useApiManager();
	const employeeCtrl = apiManager.factory(EmployeeRestControllerApiFactory);
	const [reminders, setReminders] = useState<PersonReminderList[]>([]);
	const [modalOpen, setModalOpen] = useState<boolean>(true);
	const [reminderIdx, setReminderIdx] = useState<number>(0);

	const shuffle = (array: PersonReminderList[]) => {

		let currentIndex = array.length, randomIndex;

		// While there remain elements to shuffle.
		while (currentIndex != 0) {

			// Pick a remaining element.
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex--;

			// And swap it with the current element.
			[array[currentIndex], array[randomIndex]] = [
				array[randomIndex], array[currentIndex]];
		}

		return array;

	}

	useEffect(() => {
		apiManager.execute(employeeCtrl.getEmployeeReminders())
		.then(reminders => {
			if (reminders && reminders.length) {
				setReminders(shuffle(reminders));
			}
		});
	}, []);


	return (
		<>
			{reminders && reminders.length > 0 && (
				<>
					<Modal
						open={modalOpen}
						onClose={_ => setModalOpen(!modalOpen)}
						aria-labelledby="reminder-modal-title"
						aria-describedby="reminder-modal-description">
						<Box sx={employeeModalStyle}>
							<Typography id="reminder-modal-title" variant="h6" component="h2">
								Recuerda que...
							</Typography>
							<Box id="reminder-modal-description" sx={{mt: 2}}>
								<Box pb={3}>
									{(reminderIdx + 1) + " de " + reminders.length + ": "}
									{reminders[reminderIdx].content || ""}
								</Box>

								<Button variant={"contained"} color={"secondary"} sx={{marginRight: "1em"}}
										onClick={_ => setModalOpen(false)}>
									Cerrar
								</Button>
								{reminders.length > 1 && (
									<Button variant={"contained"}
											onClick={_ => setReminderIdx((reminderIdx + 1) % reminders.length)}>
										Ver otro
									</Button>
								)}
							</Box>
						</Box>
					</Modal>
				</>
			)}
		</>
	)
}