import {FormSelectItem} from "./form_inputs/form_select";
import {
	AdminSharedRestControllerApiFactory,
	ApplicantState,
	ApplicantStateRestControllerApiFactory,
	BackupFrequencyEnum,
	BackupTypeEnum,
	BillDiscount,
	BillDiscountRestControllerApiFactory,
	Budget,
	BudgetRestControllerApiFactory,
	Candidate,
	CandidateEventTypeEnum,
	CandidateLevelTest,
	CandidateLevelTestRestControllerApiFactory,
	CandidateRestControllerApiFactory,
	CandidateSource,
	CandidateSourceRestControllerApiFactory,
	CandidateState,
	CandidateStateRestControllerApiFactory,
	CandidateType,
	CandidateTypeRestControllerApiFactory,
	CandidateWorkTypeEnum,
	Configuration,
	Customer,
	EmpEvaluationRestControllerApiFactory,
	EmployeeRestControllerApiFactory,
	EmpUploadRestControllerApiFactory,
	EvaluationPoint,
	EvaluationPointRestControllerApiFactory,
	EvaluationResult,
	EvaluationResultRestControllerApiFactory,
	HolidayCalendarEnum,
	PayrollPackTypeEnum,
	PermissionRolEnum,
	Person,
	PersonDocumentType,
	PersonDocumentTypeRestControllerApiFactory,
	Recruitment,
	RecruitmentRestControllerApiFactory,
	RecruitmentStatusEnum,
	SalaryRoleEnum,
	SalaryRoleLevelEnum,
	Skill,
	SkillLevel,
	SkillLevelRestControllerApiFactory,
	SkillRestControllerApiFactory,
	SupportPoint,
	SupportPointRestControllerApiFactory,
	SupportResult,
	SupportResultRestControllerApiFactory,
	Template,
	TemplateRestControllerApiFactory,
	TrainingLevel,
	TrainingLevelRestControllerApiFactory,
	UserAuthItemTypeEnum,
	VacationStateEnum,
	VacationTypeEnum,
	WikiPageListTypeEnum,
	WorkCenter,
	WorkCenterRestControllerApiFactory,
} from "arteco-api-client-ts";
import {ApiManager, useApiManager} from "../utils/api";
import {COUNTRIES_DATA, SPAIN_REGIONS_DATA} from "../utils/countries";

export interface TypeConf<T> {
	supplier: (apiManager: ApiManager, term?: string) => Promise<T[]>;
	valuer: (bean: T) => string | number;
	labeler: (bean: T) => string;
	isEnum?: boolean;
}

export interface TypeManager<T> {
	finalValue(selectedItems: T[]): T | T[];

	selected(selectedItems: T[], item: T): boolean;

	select(selectedItems: T[], item: T): T[];
}

export interface Typer<T> {
	supplier: (term?: string) => Promise<T[]>;
	typeManager: TypeManager<T>;
}

export function useType<T>(type: TypeConf<T>, multiple?: boolean): Typer<T> {
	const apiManager = useApiManager();
	return {
		supplier: (term) => type.supplier(apiManager, term),
		typeManager: getTypeManger(type, multiple),
	} as Typer<T>;
}

export const types = {
	discounts: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(
				apiManager,
				BillDiscountRestControllerApiFactory
			);
			return service.listBillDiscount(term).then((resp) => resp.data);
		},
		labeler: (discount) => discount.name,
		valuer: (discount) => discount.id,
	} as TypeConf<BillDiscount>,

	budgets: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(
				apiManager,
				BudgetRestControllerApiFactory
			);
			return service.listBudget(term).then((resp) => resp.data);
		},
		labeler: (budget) => budget.id ? (budget.customer?budget.customer.shortName + " - ":"") + budget.title : "",
		valuer: (budget) => budget.id,
	} as TypeConf<Budget>,

	templates: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(
				apiManager,
				TemplateRestControllerApiFactory
			);
			return service.listTemplate(term).then((resp) => resp.data);
		},
		labeler: (template) => template.name,
		valuer: (template) => template.id,
	} as TypeConf<Template>,

	customers: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(
				apiManager,
				AdminSharedRestControllerApiFactory
			);
			return service.retrieveCustomers(term).then((resp) => resp.data);
		},
		labeler: (bean) => {
			let name = bean.shortName;
			if (name && bean.skipDedications) {
				name += " (V)";
			}
			return name;
		},
		valuer: (bean) => bean?.id,
	} as TypeConf<Customer>,

	people: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(
				apiManager,
				AdminSharedRestControllerApiFactory
			);
			return service.retrievePeople(term).then((resp) => resp.data);
		},
		labeler: (bean) => {
			let res = bean.surnamesAndName;
			if (bean.id && bean.dismissed) {
				res = res + " (baja)";
			}
			return res;
		},
		valuer: (bean) => bean?.id,
	} as TypeConf<Person>,

	personDocumentTypes: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(
				apiManager,
				PersonDocumentTypeRestControllerApiFactory
			);
			return service.listPersonDocumentType(term).then((resp) => resp.data);
		},
		labeler: (bean) => bean.name,
		valuer: (bean) => bean?.id,
	} as TypeConf<PersonDocumentType>,

	personDocumentTypesAsEmployee: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(
				apiManager,
				EmpUploadRestControllerApiFactory
			);
			return service.getEmployeeUploadTypes().then((resp) => resp.data);
		},
		labeler: (bean) => bean.name,
		valuer: (bean) => bean?.id,
	} as TypeConf<PersonDocumentType>,

	recruitmentStatus: {
		supplier: (apiManager: ApiManager, term?: string) => {
			return new Promise<FormSelectItem[]>((resolve) => {
				resolve(enumToItems(RecruitmentStatusEnum, term));
			});
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.value,
		isEnum: true,
	} as TypeConf<FormSelectItem>,

	role: {
		supplier: (apiManager: ApiManager, term?: string) => {
			return new Promise<FormSelectItem[]>((resolve) => {
				resolve(enumToItems(SalaryRoleEnum, term));
			});
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.value,
		isEnum: true,
	} as TypeConf<FormSelectItem>,

	roleLevel: {
		supplier: (apiManager: ApiManager, term?: string) => {
			return new Promise<FormSelectItem[]>((resolve) => {
				resolve(enumToItems(SalaryRoleLevelEnum, term));
			});
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.value,
		isEnum: true,
	} as TypeConf<FormSelectItem>,

	calendars: {
		supplier: (apiManager: ApiManager, term?: string) => {
			return new Promise<FormSelectItem[]>((resolve) => {
				resolve(enumToItems(HolidayCalendarEnum, term));
			});
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.value,
		isEnum: true,
	} as TypeConf<FormSelectItem>,

	workTypes: {
		supplier: (apiManager: ApiManager, term?: string) => {
			return new Promise<FormSelectItem[]>((resolve) => {
				resolve(enumToItems(CandidateWorkTypeEnum, term));
			});
		},
		labeler: (bean) => {
			switch (bean.label) {
				case CandidateWorkTypeEnum.FULL_DAY:
					return 'Jornada completa';
				case CandidateWorkTypeEnum.PART_DAY:
					return 'Jornada parcial';
				case CandidateWorkTypeEnum.PRACTICE:
					return 'Prácticas';
				default:
					throw "Tipo de jornada no reconocido " + bean.label;
			}
		},
		valuer: (bean) => bean.value,
		isEnum: true,
	} as TypeConf<FormSelectItem>,

	candidateEventTypes: {
		supplier: (apiManager: ApiManager, term?: string) => {
			return new Promise<FormSelectItem[]>((resolve) => {
				resolve(enumToItems(CandidateEventTypeEnum, term));
			});
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.value,
		isEnum: true,
	} as TypeConf<FormSelectItem>,


	applicantStatus: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(
				apiManager,
				ApplicantStateRestControllerApiFactory
			);
			return service.listApplicantState(term).then((resp) => resp.data);
		},
		labeler: (bean) => bean.name,
		valuer: (bean) => bean.id,
	} as TypeConf<ApplicantState>,

	candidateTypes: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(
				apiManager,
				CandidateTypeRestControllerApiFactory
			);
			return service.listCandidateType(term).then((resp) => resp.data);
		},
		labeler: (bean) => bean.name,
		valuer: (bean) => bean.id,
	} as TypeConf<CandidateType>,

	candidateStates: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(
				apiManager,
				CandidateStateRestControllerApiFactory
			);
			return service.listCandidateState(term).then((resp) => resp.data);
		},
		labeler: (bean) => bean.name,
		valuer: (bean) => bean.id,
	} as TypeConf<CandidateState>,

	backupTypes: {
		supplier: (apiManager: ApiManager, term?: string) => {
			return new Promise<FormSelectItem[]>((resolve) => {
				resolve(enumToItems(BackupTypeEnum, term));
			});
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.value,
		isEnum: true,
	} as TypeConf<FormSelectItem>,

	userTypes: {
		supplier: (apiManager: ApiManager, term?: string) => {
			return new Promise<FormSelectItem[]>((resolve) => {
				resolve(enumToItems(UserAuthItemTypeEnum, term));
			});
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.value,
		isEnum: true,
	} as TypeConf<FormSelectItem>,

	userPermission: {
		supplier: (apiManager: ApiManager, term?: string) => {
			return new Promise<FormSelectItem[]>((resolve) => {
				resolve(enumToItems(PermissionRolEnum, term));
			});
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.value,
		isEnum: true,
	} as TypeConf<FormSelectItem>,

	backupFrequency: {
		supplier: (apiManager: ApiManager, term?: string) => {
			return new Promise<FormSelectItem[]>((resolve) => {
				resolve(enumToItems(BackupFrequencyEnum, term));
			});
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.value,
		isEnum: true,
	} as TypeConf<FormSelectItem>,

	payrollPackType: {
		supplier: (apiManager: ApiManager, term?: string) => {
			return new Promise<FormSelectItem[]>((resolve) => {
				resolve(enumToItems(PayrollPackTypeEnum, term));
			});
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.value,
		isEnum: true,
	} as TypeConf<FormSelectItem>,

	wikiPageType: {
		supplier: (apiManager: ApiManager, term?: string) => {
			return new Promise<FormSelectItem[]>((resolve) => {
				resolve(enumToItems(WikiPageListTypeEnum, term));
			});
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.value,
		isEnum: true,
	} as TypeConf<FormSelectItem>,

	sources: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(
				apiManager,
				CandidateSourceRestControllerApiFactory
			);
			return service.listCandidateSource(term).then((resp) => resp.data);
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.id,
	} as TypeConf<CandidateSource>,

	countries: {
		supplier: (apiManager: ApiManager, term?: string) => {
			return new Promise<FormSelectItem[]>((resolve) => {
				const countries = term
					? COUNTRIES_DATA.filter((country) => country.label.indexOf(term) >= 0)
					: COUNTRIES_DATA;

				resolve(countries);
			});
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.value,
		isEnum: true,
	} as TypeConf<FormSelectItem>,

	regions: {
		supplier: (apiManager: ApiManager, term?: string) => {
			return new Promise<FormSelectItem[]>((resolve) => {
				const regions = term
					? SPAIN_REGIONS_DATA.filter((region) => region.label.indexOf(term) >= 0)
					: SPAIN_REGIONS_DATA;

				resolve(regions);
			});
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.value,
		isEnum: true,
	} as TypeConf<FormSelectItem>,

	trainingLevels: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(
				apiManager,
				TrainingLevelRestControllerApiFactory
			);
			return service.listTrainingLevel(term).then((resp) => resp.data);
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.id,
	} as TypeConf<TrainingLevel>,

	employeeTrainingLevels: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(
				apiManager,
				EmployeeRestControllerApiFactory
			);
			return service.getEmployeeTrainingLevels().then((resp) => resp.data);
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.id,
	} as TypeConf<TrainingLevel>,

	employeeWorkCenters: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(
				apiManager,
				EmployeeRestControllerApiFactory
			);
			return service.getEmployeeWorkCenters().then((resp) => resp.data);
		},
		labeler: (bean) => bean.name,
		valuer: (bean) => bean.id,
	} as TypeConf<WorkCenter>,

	workCenters: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(
				apiManager,
				WorkCenterRestControllerApiFactory
			);
			return service.listWorkCenter(term).then((resp) => resp.data);
		},
		labeler: (bean) => bean.name,
		valuer: (bean) => bean.id,
	} as TypeConf<WorkCenter>,

	skills: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(apiManager, SkillRestControllerApiFactory);
			return service.listSkill(term).then((resp) => resp.data);
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.id,
	} as TypeConf<Skill>,

	skillLevels: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(
				apiManager,
				SkillLevelRestControllerApiFactory
			);
			return service.listSkillLevel(term).then((resp) => resp.data);
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.id,
	} as TypeConf<SkillLevel>,

	evaluationPoint: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(apiManager, EvaluationPointRestControllerApiFactory);
			return service.listEvaluationPoint(term).then((resp) => resp.data);
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.id,
	} as TypeConf<EvaluationPoint>,

	evaluationPointAsEmployee: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(apiManager, EmpEvaluationRestControllerApiFactory);
			return service.getEvaluationPoints().then((resp) => resp.data);
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.id,
	} as TypeConf<EvaluationPoint>,

	evaluationResult: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(
				apiManager,
				EvaluationResultRestControllerApiFactory
			);
			return service.listEvaluationResult(term).then((resp) => resp.data);
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.id,
	} as TypeConf<EvaluationResult>,

	evaluationResultAsEmployee: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(apiManager, EmpEvaluationRestControllerApiFactory);
			return service.getPointResults().then((resp) => resp.data);
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.id,
	} as TypeConf<EvaluationResult>,

	supportPoint: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(apiManager, SupportPointRestControllerApiFactory);
			return service.listSupportPoint(term).then((resp) => resp.data);
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.id,
	} as TypeConf<SupportPoint>,

	supportResult: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(
				apiManager,
				SupportResultRestControllerApiFactory
			);
			return service.listSupportResult(term).then((resp) => resp.data);
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.id,
	} as TypeConf<SupportResult>,

	vacationState: {
		supplier: (apiManager: ApiManager, term?: string) => {
			return new Promise<FormSelectItem[]>((resolve) => {
				resolve(enumToItems(VacationStateEnum, term));
			});
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.value,
		isEnum: true,
	} as TypeConf<FormSelectItem>,

	vacationType: {
		supplier: (apiManager: ApiManager, term?: string) => {
			return new Promise<FormSelectItem[]>((resolve) => {
				resolve(enumToItems(VacationTypeEnum, term));
			});
		},
		labeler: (bean) => bean.label,
		valuer: (bean) => bean.value,
		isEnum: true,
	} as TypeConf<FormSelectItem>,

	candidates: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(
				apiManager,
				CandidateRestControllerApiFactory
			);
			return service.listCandidate(term).then((resp) => resp.data);
		},
		labeler: (bean) => bean.surnamesAndName
			+ (bean.type ? " (" + bean.type.name + ")" : "") + " "
			+ (bean.source ? " (" + bean.source.label + ")" : ""),
		valuer: (bean) => bean.id,
	} as TypeConf<Candidate>,

	candidateLevelTests: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(
				apiManager,
				CandidateLevelTestRestControllerApiFactory
			);
			return service.listCandidateLevelTest(term).then((resp) => resp.data);
		},
		labeler: (bean) => bean.name,
		valuer: (bean) => bean.id,
	} as TypeConf<CandidateLevelTest>,

	recruitment: {
		supplier: (apiManager: ApiManager, term?: string) => {
			const [service] = prepareConfig(
				apiManager,
				RecruitmentRestControllerApiFactory
			);
			return service.listRecruitment(undefined, term).then((resp) => resp.data);
		},
		labeler: (bean) => bean.title,
		valuer: (bean) => bean.id,
	} as TypeConf<Recruitment>,
};

function prepareConfig<T>(
	apiManager: ApiManager,
	serviceClazz: (config: Configuration) => T
): [T] {
	const service: T = apiManager.factory(serviceClazz);
	return [service];
}

export const enumToItems = (enumType: any, term?: string): FormSelectItem[] => {
	const newTypes: FormSelectItem[] = [];
	Object.keys(enumType)
	.filter((value) => value.length >= 2)
	.filter((value) => !term || value.indexOf(term) >= 0)
	.forEach((value) => {
		newTypes.push({value: value, label: value} as FormSelectItem);
	});
	return newTypes;
};

class BaseTypeManager<T> {
	type: TypeConf<T>;
	multiple: boolean;

	constructor(type: TypeConf<T>, multiple?: boolean) {
		this.type = type;
		this.multiple = multiple || false;
	}

	finalValue(selectedItems: T[]): T | T[] {
		let result: T | T[] = [];
		if (this.multiple) {
			result = selectedItems;
		} else if (selectedItems.length) {
			result = selectedItems[0];
		}
		return result;
	}
}

class SimpleTypeManager<T> extends BaseTypeManager<T> implements TypeManager<T> {
	constructor(type: TypeConf<T>, multiple?: boolean) {
		super(type, multiple);
	}

	finalValue(selectedItems: T[]): T | T[] {
		return super.finalValue(selectedItems);
	}

	selected(selectedItems: T[], item: T): boolean {
		const value = this.type.valuer(item) as any as T;
		return selectedItems.find((i) => i == value) != undefined;
	}

	select(selectedItems: T[], item: T): T[] {
		const value = this.type.valuer(item) as any as T;

		let newSelection: T[];
		const lookedItem = selectedItems.find((i) => i == value);
		if (lookedItem) {
			// si existe se quita
			newSelection = selectedItems.filter((i) => i != value);
		} else {
			// si no existe
			newSelection = [value];
			if (this.multiple) {
				selectedItems.map((i) => newSelection.push(i));
			}
		}
		return newSelection;
	}
}

class ComplexTypeManager<T> extends BaseTypeManager<T> implements TypeManager<T> {
	constructor(type: TypeConf<T>, multiple?: boolean) {
		super(type, multiple);
	}

	finalValue(selectedItems: T[]): T | T[] {
		return super.finalValue(selectedItems);
	}

	selected(selectedItems: T[], item: T): boolean {
		return (
			selectedItems.find((i) => this.type.valuer(i) == this.type.valuer(item)) != undefined
		);
	}

	select(selectedItems: T[], item: T): T[] {
		let newSelection: T[];
		const lookedItem = selectedItems.find((i) => this.type.valuer(i) == this.type.valuer(item));
		if (lookedItem) {
			// si existe se quita
			newSelection = selectedItems.filter(
				(i) => this.type.valuer(i) != this.type.valuer(item)
			);
		} else {
			// si no existe
			newSelection = [item];
			if (this.multiple) {
				selectedItems.map((i) => newSelection.push(i));
			}
		}
		return newSelection;
	}
}

function getTypeManger<T>(type: TypeConf<T>, multiple?: boolean): TypeManager<T> {
	if (type.isEnum) {
		return new SimpleTypeManager<T>(type, multiple);
	} else {
		return new ComplexTypeManager<T>(type, multiple);
	}
}
