import {WikiPage, WikiPageRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {useApiManager} from "../../../../components/utils/api";
import {SECTION_BILLING, SECTION_HR, SECTION_RECRUITMENT, SECTION_SYSTEM} from "./form";
import {Grid} from "@mui/material";

export interface WikiPageListPageProps {
    section: string;
}


export const WikiPageListPage = ({section}: WikiPageListPageProps) => {
    const apiManager = useApiManager();

    let pathPart: string | undefined = undefined;
    switch (section) {
        case SECTION_HR:
            pathPart = "employee";
            break;
        case SECTION_RECRUITMENT:
            pathPart = "recruitment";
            break;
        case SECTION_BILLING:
            pathPart = "billing";
            break;
        case SECTION_SYSTEM:
            pathPart = "company";
            break;
        default:
            throw new Error("Unknown section " + section);
    }

    const listConfig: AppListConfig<WikiPage> = {
        title: "Wiki de " + section,
        onClick: "/app/admin/" + pathPart + "/wiki",
        decorator: (bean: WikiPage) => <Grid container>
            <Grid item sm> {bean.title}</Grid>
            <Grid item sm={2}>{bean.visible ? "visible" : ""}</Grid>
            <Grid item sm={2}>{bean.type}</Grid>
        </Grid>,
        supplier: async (currentPage, size, term, showDismissed) => {
            
            const ctrl = apiManager.factory(WikiPageRestControllerApiFactory);
            return ctrl.pageWikiPage(section, currentPage, size, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig}/>
        </>
    );
};