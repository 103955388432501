import {SupportLink, SupportLinkRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {useApiManager} from "../../../../components/utils/api";
import {Grid} from "@mui/material";
import {COLOR_DISMISSED} from "../../../../components/layout/colors";
import {dateToString} from "../../../../components/utils/dates";

export const SupportLinkListPage = () => {
	const apiManager = useApiManager();


	const getColor = (value: number): string => {
		if (value < 0) value = 0;
		if (value > 40) value = 40;
		// Interpolar el matiz de rojo (0°) a verde (120°)
		const hue = (120 * value) / 40;
		return `hsl(${hue}, 100%, 30%)`;
	}

	const listConfig: AppListConfig<SupportLink> = {
		title: "Listado de enlaces de evaluación de clientes",
		onClick: "/app/admin/employee/supportlink",
		decorator: (bean: SupportLink) => (
			<Grid
				container
				style={{color: bean.dismissed ? COLOR_DISMISSED : "inherit"}}>
				<Grid item sm={2}>
					{dateToString(bean.created)}
				</Grid>
				<Grid item sm={2}>
					{bean.name}
				</Grid>
				<Grid item sm={2}>
					{dateToString(bean.evaluationDate)}
				</Grid>
			</Grid>
		),
		supplier: async (currentPage, size, term, showDismissed) => {
			
			const ctrl = apiManager.factory(SupportLinkRestControllerApiFactory);
			return ctrl.pageSupportLink(currentPage, size, undefined, term, showDismissed);
		},
	};

	return (
		<>
			<AppList config={listConfig}/>
		</>
	);
};