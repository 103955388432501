import React from "react";

import {useApiManager} from "../../../../components/utils/api";
import {EvaluationPoint, EvaluationPointRestControllerApiFactory} from "arteco-api-client-ts";

import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {Grid} from "@mui/material";
import {useForm, UseFormProps} from "../../../../components/form/form_model";

export const EvaluationPointEditPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(EvaluationPointRestControllerApiFactory);

    const form = useForm({
        fetcher: (id) => ctrl.getEvaluationPoint(id),
        updater: (bean) => ctrl.saveEvaluationPoint(bean),
        remover: (id) => ctrl.deleteOrDismissEvaluationPoint(id),
        factory: () => {return {} as EvaluationPoint;
        },
    } as UseFormProps<EvaluationPoint>);

    return (
        <>
            <FormContent form={form} title={"Pregunta de evaluación"}>
                <Grid container spacing={3}>
                    <Grid item sm={4}>
                        <FormInput type={"text"} field={"position"} label={"Posición"} />
                    </Grid>
                    <Grid item sm>
                        <FormInput type={"text"} field={"label"} label={"Pregunta"} />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput
                            type={"text"}
                            field={"description"}
                            label={"Descripción"}
                            rows={3}
                        />
                    </Grid>
                </Grid>
            </FormContent>
        </>
    );
};