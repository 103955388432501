import React, {useEffect, useState} from "react";
import {Candidate, CandidateLevelSolution, CandidateLevelSolutionRestControllerApiFactory, CandidateRestControllerApiFactory} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import FormFile from "../../../../components/form/form_inputs/form_file";
import {types} from "../../../../components/form/types";
import FormAutocomplete from "../../../../components/form/form_inputs/form_autocomplete";
import {Button, Grid} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import {useNavigate, useParams} from "react-router-dom";
import {parsePathId} from "../../../../components/form/form_utils";


export const CandidateLevelSolutionEditPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(CandidateLevelSolutionRestControllerApiFactory);
    const candidateCtrl = apiManager.factory(CandidateRestControllerApiFactory);
    
    const navigate = useNavigate();

    const params = useParams();
    const candidateId = parsePathId(params, "candidateId");
    const [candidate, setCandidate] = useState<Candidate | undefined>(undefined);

    useEffect(()=>{
        if (candidateId){
            apiManager
                .execute(candidateCtrl.getCandidate(candidateId))
                .then(c => setCandidate(c));
        }
    },[candidateId]);

    const form = useForm({
        fetcher: (id) => ctrl.getCandidateLevelSolution(id),
        updater: (bean) => ctrl.saveCandidateLevelSolution(bean),
        remover: (id) => ctrl.deleteOrDismissCandidateLevelSolution(id),
        factory: () => {
            let result = {} as CandidateLevelSolution;
            if (candidateId) {
                result = {
                    ...result,
                    candidate: {id: candidateId} as Candidate
                };
            }
            return result;
        },

    } as UseFormProps<CandidateLevelSolution>);

    return (
        <>
            <FormContent form={form} title={candidate
                ? "Soluciones de " + candidate.surnamesAndName
                : "Formulario de Solución de Pruebas de Nivel"}>

                <FormAutocomplete field={"test"} label={"Enunciado"} type={types.candidateLevelTests}/>

                {!candidateId && (
                    <Grid container spacing={3}>
                        <Grid item sm>
                            <FormAutocomplete field={"candidate"} label={"Candidato"} type={types.candidates}/>
                        </Grid>
                        <Grid item sm={1}>
                            <Button variant={"outlined"} size={"large"} onClick={() => {
                                if (form.propModel.value?.candidate.id) {
                                    navigate("/app/admin/recruitment/candidate/" + form.propModel.value?.candidate.id);
                                }
                            }}><PersonIcon/></Button>
                        </Grid>
                    </Grid>
                )}


                <FormInput type={"number"} field={"points"} label={"Puntos (0-10)"}/>

                <FormFile
                    field={"attachment"}
                    label={"Solución de la prueba de nivel (máx 5Mb)"}
                    onDownload={() => ctrl.downloadCandidateLevelSolution(form.getIdOrErr())}
                />

                <FormInput type={"text"} field={"comments"}
                           label={"Comentarios Privados"} rows={10}/>


            </FormContent>
        </>
    );
};