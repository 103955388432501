import {BudgetRestControllerApiFactory, BudgetSummary} from "arteco-api-client-ts";
import {useApiManager} from "../../../../components/utils/api";
import {useEffect, useState} from "react";
import {Card, CardContent, Grid, LinearProgress, Typography} from "@mui/material";
import {Box} from "@mui/system";

export interface BudgetSummariesProps {
	month: string;
	year: number;
}

export function BudgetSummaries({year, month}: BudgetSummariesProps): JSX.Element {
	const apiManager = useApiManager();
	const budgetCtrl = apiManager.factory(BudgetRestControllerApiFactory);

	const [budgetSummaries, setBudgetSummaries] = useState<BudgetSummary[]>([]);

	const loadBudgetSummaries = () => {
		apiManager.execute(budgetCtrl.getActiveSummariesBudget(year, parseInt(month)))
		.then(summary => setBudgetSummaries(summary));
	}

	useEffect(() => {
		loadBudgetSummaries();
	}, [year, month]);

	return (
		<>
			<Grid container spacing={3} alignItems="stretch">
				{budgetSummaries.map((budget, bidx) => {

					const progress = (budget.totalHours || 0) > 0
						? ((budget.spentHours || 0) / (budget.totalHours || 0)) * 100
						: 100;

					return (
						<Grid item key={`budget-${bidx}`}>
							<Card sx={{height: "100%", boxShadow: 3, borderRadius: 2}}>
								<CardContent>
									<Typography variant="body1" fontWeight="bold" gutterBottom>
										{budget.customer} - {budget.budgetTitle}
									</Typography>

									<RowConcept title={"Spent hours"} amount={budget.spentHours}  />
									<RowConcept title={"Total hours"} amount={budget.totalHours}  />
									<RowConcept title={"Billed"} amount={budget.billed}/>
									<RowConcept title={"Budget"} amount={budget.amount}/>

									<LinearProgress
										variant="determinate"
										value={progress}
										sx={{mt: 2, height: 8, borderRadius: 2}}
										color={progress > 100 ? "error" : "primary"}
									/>
								</CardContent>
							</Card>
						</Grid>
					);
				})}

			</Grid>
		</>
	);
}

interface RowConceptProp {
	title: string;
	amount: number | undefined;
}

const RowConcept = ({title, amount}: RowConceptProp) => {
	return (
		<>
			<Typography variant="body1" color="text.secondary">
				<Grid container spacing={2}>
					<Grid item xs>
						<Typography noWrap>
							{title}
						</Typography>
					</Grid>
					<Grid item xs sx={{textAlign: 'right'}}>
						{(amount || 0) > 0 && (
							<>
								<strong>{amount?.toFixed(2)}</strong>
							</>
						)}
						&nbsp;
					</Grid>
				</Grid>
			</Typography>
		</>
	);
}