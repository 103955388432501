import {PersonDocumentType, PersonDocumentTypeRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {COLOR_DISMISSED} from "../../../../components/layout/colors";
import {Grid} from "@mui/material";
import {useApiManager} from "../../../../components/utils/api";
import React from "react";

export const PersonDocumentTypeListPage = () => {
    const apiManager = useApiManager();

    const listConfig: AppListConfig<PersonDocumentType> = {
        title: "Tipos de Documento de Persona",
        onClick: "/app/admin/employee/persondoctype",
        decorator: (bean: PersonDocumentType) => (
            <Grid container style={{color: bean.dismissed ? COLOR_DISMISSED : ""}}>
                <Grid item sm>
                    {bean.name}
                </Grid>
                <Grid item sm={3}>
                    {bean.requiredYearMonth ? "M/A" : ""}
                </Grid>
                <Grid item sm={3}>
                    {bean.requiredExpiration ? "Exp" : ""}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            
            const ctrl = apiManager.factory(PersonDocumentTypeRestControllerApiFactory);
            return ctrl.pagePersonDocumentType(currentPage, size, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig}/>
        </>
    );
};