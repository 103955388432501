import {Alert, Box, Grid, IconButton, List, ListItem, ListItemText, Tab, Typography} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {H4, Typographies} from "../../../../components/utils/typography_consts";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import React, {useState} from "react";
import {Occupation_calendar} from "./occupation_calendar";
import {Occupation, OccupationPersonMonth,} from "arteco-api-client-ts";
import DedicationTable from "./dedication/dedication_table";


export interface OccupationIndividualProps {
	occupation: Occupation;
	month: string;
	year: number;
}

export const OccupationIndividual = ({occupation, month, year}: OccupationIndividualProps) => {

	const [selectedTab, setSelectedTab] = useState<string>("dedication");
	const [selectedPerson, setSelectedPerson] = useState<OccupationPersonMonth | undefined>(undefined);

	const getWorkedHours = (personId: number | undefined): number => {
		if (personId && occupation && occupation.planningByMonthAndPersonId) {
			const personMonth = occupation.planningByMonthAndPersonId[month][personId.toString()];
			return personMonth.workedHours || 0;
		}
		return 0;
	};

	return (
		<Grid container wrap={"nowrap"} spacing={3}>
			<Grid item sm={4}>
				<div style={{
					maxHeight: "70vh",
					overflow: "auto",
					backgroundColor: "rgba(243,243,243,0.37)",
					borderRight: "1px solid gray",
					borderBottom: "1px solid gray"
				}}>
					<List>
						{occupation.peopleByMonth && (
							<>
								{(occupation.peopleByMonth[month] || []).map((occupationSummary, pIdx) => (
									<ListItem
										key={pIdx}
										divider
										button
										selected={selectedPerson?.personId == occupationSummary.personId}
										secondaryAction={
											<>
												<small style={{
													color: ((occupationSummary.hourlyRate || 0) <= (occupationSummary.salary || 0) / 1000) ? "red" : "gray",
													marginLeft: "0.5em"
												}}>
													{(occupationSummary.hourlyRate || 0)} / {((occupationSummary.salary || 0) / 1000).toFixed(1)}K
												</small>
												<IconButton style={{backgroundColor: "white"}} onClick={(e) => {
													window.open("/app/admin/employee/person/" + occupationSummary.personId);
												}} edge="end" aria-label="delete">
													<PersonIcon/>
												</IconButton>
											</>

										}
										onClick={() => setSelectedPerson(occupationSummary)}>
										<ListItemText style={{whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
											{pIdx + 1 + " - "}
											<span
												style={{color: ((occupationSummary.availableHours || 0) > getWorkedHours(occupationSummary.personId)) ? "red" : "inherit"}}>
												{occupationSummary.personName}
											</span>
											&nbsp; | &nbsp;
											<small style={{
												color: "gray",
												marginLeft: "0.5em"
											}}>{occupationSummary.personHelp}</small>
										</ListItemText>
									</ListItem>
								))}
							</>
						)}

					</List>
				</div>
			</Grid>
			<Grid item sm sx={{padding: 1, backgroundColor: "white", zIndex: 10}}>
				{!selectedPerson && (
					<Alert sx={{m: 3}} icon={<ArrowBackIcon/>}>
						<Typography variant={Typographies.body1} component={H4}>
							Selecciona una persona
						</Typography>
					</Alert>
				)}
				{selectedPerson && (
					<>

						<TabContext value={selectedTab}>
							<Box sx={{borderBottom: 1, borderColor: 'divider'}}>
								<TabList onChange={(event: React.SyntheticEvent, newValue: string) => {
									setSelectedTab(newValue);
								}}>
									<Tab label="Dedicación" value="dedication"/>
									<Tab label="Calendario" value="calendar"/>
								</TabList>
							</Box>
							<TabPanel value="dedication" sx={{padding: 0, paddingTop: 1}}>
								<DedicationTable showPerson={false}
												 personId={selectedPerson.personId}
												 occupation={occupation}
												 month={month}
												 year={year}/>

							</TabPanel>
							<TabPanel value="calendar" sx={{padding: 0, paddingTop: 1}}>
								<Occupation_calendar month={month}
													 occupation={occupation}
													 personId={selectedPerson.personId as number}/>
							</TabPanel>
						</TabContext>

					</>
				)}
			</Grid>
		</Grid>
	);
}