import {Badge, Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useApiManager} from "../../../../components/utils/api";
import {
    ApplicantRestControllerApiFactory,
    CandidateDocumentRestControllerApiFactory,
    CandidateEventRestControllerApiFactory,
    CandidateLevelSolutionRestControllerApiFactory
} from "arteco-api-client-ts";
import CheckIcon from "@mui/icons-material/Check";
import {CalendarMonth, Description, LocalMall} from "@mui/icons-material";


export interface CandidateSummaryAlertProps {
    candidateId: number | undefined;
}

interface CandidateSummary {
    docs: number,
    events: number,
    solutions: number,
    applications: number
}

export const CandidateSummaryAlert = ({candidateId}: CandidateSummaryAlertProps) => {
    const manager = useApiManager();
    

    const documentCtrl = manager.factory(CandidateDocumentRestControllerApiFactory);
    const eventCtrl = manager.factory(CandidateEventRestControllerApiFactory);
    const applicationCtrl = manager.factory(ApplicantRestControllerApiFactory);
    const solutionCtrl = manager.factory(CandidateLevelSolutionRestControllerApiFactory);

    const [summary, setSummary] = useState<CandidateSummary | undefined>(undefined);

    useEffect(() => {
        if (candidateId) {
            Promise.all([
                manager.execute(documentCtrl.countCandidateDocument(candidateId)),
                manager.execute(eventCtrl.countCandidateEvent(candidateId)),
                manager.execute(solutionCtrl.countCandidateLevelSolution(candidateId)),
                manager.execute(applicationCtrl.countApplicant(candidateId))
            ]).then(resp => {
                const docs = resp[0];
                const events = resp[1];
                const solutions = resp[2];
                const applications = resp[3];
                setSummary({docs, events, solutions, applications});
            });
        }
    }, [candidateId]);

    return (
        <Box sx={{my: 3, width:"100%"}} color={"info"}>
            {!summary && (
                <p>Obteniendo datos...</p>
            )}
            {summary && (
                <>
                    <Badge sx={{mr:2}} badgeContent={summary.applications} color="primary">
                        <LocalMall color="action" />
                    </Badge>

                    <Badge sx={{mr:2}}  badgeContent={summary.events} color="primary">
                        <CalendarMonth color="action" />
                    </Badge>

                    <Badge sx={{mr:2}}  badgeContent={summary.solutions} color="primary">
                        <CheckIcon color="action" />
                    </Badge>

                    <Badge badgeContent={summary.docs} color="primary">
                        <Description color="action" />
                    </Badge>
                </>
            )}
        </Box>
    )
}