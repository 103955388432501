import AppList, {AppListConfig} from "../../../../../components/layout/list";
import {
    RecruitmentDocument,
    RecruitmentDocumentRestControllerApiFactory
} from "arteco-api-client-ts";
import React from "react";
import {useApiManager} from "../../../../../components/utils/api";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Grid, useTheme} from "@mui/material";
import {dateToString} from "../../../../../components/utils/dates";
import {useRecruitment} from "../index";
import {fileLocalSave} from "../../../../../components/form/form_inputs/form_file";
import DownloadIcon from '@mui/icons-material/Download';
import {AxiosResponse} from "axios";
import {parsePathId} from "../../../../../components/form/form_utils";

export const RecruitmentDocumentListPage = () => {
    const theme = useTheme();
    const params = useParams();
    const apiManager = useApiManager();
    
    const navigate = useNavigate();

    const recruitmentId = parsePathId(params, "recruitmentId");
    const [recruitment, setRecruitment] = useRecruitment(recruitmentId);

    const documentCtrl = apiManager.factory(RecruitmentDocumentRestControllerApiFactory);

    const getRecruitmentId = (document: RecruitmentDocument | undefined): number | undefined => {
        if (recruitment) {
            return recruitment.id;
        } else if (document) {
            return document.recruitment.id;
        } else {
            return undefined;
        }
    }

    const listConfig: AppListConfig<RecruitmentDocument> = {
        title: "Archivos de " +  (recruitment ? recruitment.title : "cargando..."),
        onClick: (document: RecruitmentDocument) => {
            const recruitmentId = getRecruitmentId(document);
            if (recruitmentId) {
                navigate("/app/admin/recruitment/recruitment/" + recruitmentId + "/document/" + document.id);
            } else {
                alert("No personId to forward!");
            }
        },
        decorator: (bean: RecruitmentDocument) =>
            <Grid container>
                <Grid item sm>{dateToString(bean.created)}</Grid>
                <Grid item sm>{bean.title}</Grid>
                <Grid item sm={2} textAlign={"right"}>
                    <Button variant={"text"} size={"small"}
                            title={"Descargar"}
                            onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (bean.id){
                            apiManager.executeRaw(documentCtrl.viewRecruitmentDocumentRecruitmentDocument(bean.id))
                                .then((resp: AxiosResponse<string>) => fileLocalSave(resp));
                        }
                    }}>
                        <DownloadIcon/>
                    </Button>
                </Grid>
            </Grid>,
        supplier: async (currentPage, size, term, showDismissed) => {
            return documentCtrl.pageRecruitmentDocument(
                recruitmentId as number,
                currentPage,
                size,
                undefined,
                term,
                showDismissed)
        },
    };

    return (
        <>
            <AppList config={listConfig} onAppend={()=> {
                if (recruitmentId) {
                    navigate("/app/admin/recruitment/recruitment/" + recruitmentId + "/document/new");
                } else {
                    alert("No recruitmentId to forward!");
                }
            }}/>
        </>
    );
};