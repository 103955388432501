import React from "react";
import {WorkCenter, WorkCenterRestControllerApiFactory} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";
import {Grid} from "@mui/material";
import FormToggle from "../../../../components/form/form_inputs/form_toggle";
import FormSelect from "../../../../components/form/form_inputs/form_select";
import {types} from "../../../../components/form/types";

export const WorkCenterEditPage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(WorkCenterRestControllerApiFactory);
    

    const form = useForm({
        fetcher: (id) => ctrl.getWorkCenter(id),
        updater: (bean) => ctrl.saveWorkCenter(bean),
        remover: (id) => ctrl.deleteOrDismissWorkCenter(id),
        factory: () => {
            return {  } as WorkCenter;
        },
    } as UseFormProps<WorkCenter>);

    return (
        <>
            <FormContent form={form} title={"Ficha del Centro"}>
                <FormInput field={"name"} type={"text"} label={"Nombre"} />
                <FormInput field={"address"} type={"text"} label={"Dirección"} />
                <Grid container spacing={3}>
                    <Grid item sm><FormInput field={"city"} type={"text"} label={"Ciudad"} /></Grid>
                    <Grid item sm><FormInput field={"postalCode"} type={"text"} label={"Código Postal"} /></Grid>
                    <Grid item sm><FormInput field={"country"} type={"text"} label={"País"} /></Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item sm><FormInput field={"places"} type={"number"} label={"Plazas"} /></Grid>
                    <Grid item sm><FormInput field={"minForOpen"} type={"number"} label={"Mínimo para abrir"}/></Grid>
                    <Grid item sm><FormToggle field={"disabled"} label={"Desactivada"}/></Grid>
                    <Grid item sm><FormSelect field={"calendar"} type={types.calendars} label={"Calendario"} /></Grid>
                </Grid>
                <FormInput field={"instructions"} type={"text"} label={"Instrucciones para el empleado"} rows={4} />
            </FormContent>
        </>
    );
};