import React, {useEffect, useState} from "react";
import {Alert, Box, Paper, Typography} from "@mui/material";
import {CandidateForm, CandidateRequestRestControllerApiFactory, Item,} from "arteco-api-client-ts";
import {useForm} from "../../components/form/form_model";
import {useApiManager} from "../../components/utils/api";
import {H1, PARAGRAPH, Typographies} from "../../components/utils/typography_consts";
import {HOME_ARTECO} from "../../components/utils/urls";
import {AppLayout} from "../../components/layout/layout";
import {useSearchParams} from "react-router-dom";
import {CandidateHeader} from "./header";
import {PubCandidateForm} from "./candidate_form";


export const CandidatePage = () => {
    const apiManager = useApiManager();
    const ctrl = apiManager.factory(CandidateRequestRestControllerApiFactory);
    

    const [searchParams, setSearchParams] = useSearchParams();
    const email = searchParams.get("email");
    const pass = searchParams.get("pass");
    const recruitmentId = parseInt(searchParams.get("rid") || "0") || 0;
    const source = searchParams.get("s");
    const mode : "s" | "c" = searchParams.get("m") == "s" ? "s" : "c";

    const [candidateForm, setCandidateForm] = useState<CandidateForm | undefined>(undefined);
    const [recruitment, setRecruitment] = useState<Item | undefined>(undefined);
    const [isSentForm, setIsSentForm] = useState(false);

    useEffect(() => {
        if (recruitmentId) {
            apiManager.execute(ctrl.getRecruitmentDetails(recruitmentId))
                .then(item => setRecruitment(item));
        }
    }, [recruitmentId]);

    const form = useForm<CandidateForm>({
        // La recuperación de candidato por usuario y contraseña está más abajo
        updater: (candidate) => ctrl.saveCandidateForm(candidate),
        postUpdate: () => {
            setIsSentForm(true);
        },
        factory: () => {
            if (email && pass) {
                return apiManager.execute(ctrl.getCandidateForm(email, pass));
            }
            return {
                mode,
                email: "",
                password: "",
                name: "",
                surnames: "",
                interest: "",
                receiveEmails: false,
                communityEmails: false,
                servicesEmails: false,
                maxTrainingLevel: {
                    label: "",
                    level: 0,
                },
                skills: {},
                country: "ES",
                recruitmentId: recruitmentId > 0 ? recruitmentId : undefined,
                source
            } as CandidateForm;
        },
    });

    return (
        <AppLayout permitAnon={true} maxWidth="md">

            <CandidateHeader title={"Únete a nuestro equipo"}
                             subTitle={"Born to be a Javalover?"}/>

            <Box>
                {isSentForm && (
                    <Paper>
                        <Box p={3}>
                            <Typography my={4}
                                        variant={Typographies.h4}
                                        component={H1}>
                                Tu solicitud ha sido enviada con éxito
                            </Typography>
                            <Typography py={3} component={PARAGRAPH}>
                                ¡Gracias por contactar con nosotros y hacernos llegar tu información!
                                En breve procederemos a revisar tu candidatura y te propondremos las vacantes
                                que más se ajusten a tu perfil con la que puedas desarrollar una <b>carrera
                                profesional</b>
                                &nbsp;dentro del sector de las tecnologías.
                            </Typography>

                            <Typography py={4} component={PARAGRAPH}>
                                Puedes continuar revisando más contenido en la página
                                &nbsp;<a href={HOME_ARTECO}>web de Arteco</a>.
                            </Typography>
                        </Box>
                    </Paper>
                )}

                {!isSentForm && (
                    <>
                        {recruitment && (
                            <Paper sx={{p:3,mt:1,mb:2}}>
                                <Typography variant={"h3"} component={"h2"}>{recruitment.value}</Typography>
                                <Alert color={"success"} sx={{my:1}}>
                                    ¿No es esta la oferta que buscas?
                                    Consulta qué <a href={"/recruitments"}>otras posiciones</a> tenemos.
                                    O <a href={"/candidate"}>inscríbete directamente</a> en nuestra base de datos.
                                </Alert>
                                <p style={{whiteSpace: "pre-wrap"}}>{recruitment.label}</p>
                            </Paper>
                        )}
                        {!recruitment && (
                            <Alert color={"info"} sx={{my:1}}>
                                A parte de inscribirte, puedes ver qué posiciones tenemos abiertas públicamente
                                en este momento en el <a href={"/recruitments"}>listado de posiciones</a>.
                            </Alert>
                        )}
                        <PubCandidateForm form={form} candidateForm={candidateForm} mode={mode}/>
                    </>

                )}
            </Box>
        </AppLayout>
    );
};