import {CandidateType, CandidateTypeRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {COLOR_DISMISSED} from "../../../../components/layout/colors";
import {Grid} from "@mui/material";
import {useApiManager} from "../../../../components/utils/api";
import React from "react";

export const CandidateTypeListPage = () => {
    const apiManager = useApiManager();

    const listConfig: AppListConfig<CandidateType> = {
        title: "Tipos de Contacto",
        onClick: "/app/admin/recruitment/candidatetype",
        decorator: (bean: CandidateType) => (
            <Grid container style={{ color: bean.dismissed ? COLOR_DISMISSED : "" }}>
                <Grid item sm>
                    {bean.name}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            
            const ctrl = apiManager.factory(CandidateTypeRestControllerApiFactory);
            return ctrl.pageCandidateType(currentPage, size, undefined, term, showDismissed);
        },
    };

    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};