import {AdminBackupRestControllerApiFactory, Backup} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {useApiManager} from "../../../../components/utils/api";
import {Grid} from "@mui/material";

export const BackupListPage = () => {
    const apiManager = useApiManager();

    const listConfig: AppListConfig<Backup> = {
        title: "Copias de seguridad ",
        onClick: "/app/admin/company/backup",
        decorator: (bean: Backup) => (
            <Grid container>
                <Grid item sm>
                    {bean.title}
                </Grid>
                <Grid item sm>
                    {bean.type}
                </Grid>
                <Grid item sm>
                    {bean.last}
                </Grid>
            </Grid>
        ),
        supplier: async (currentPage, size, term, showDismissed) => {
            const ctrl = apiManager.factory(AdminBackupRestControllerApiFactory);
            return ctrl.pageBackup(
                currentPage,
                size,
                undefined,
                term,
                showDismissed
            );
        },
    };

    return (
        <>
            <AppList config={listConfig} />
        </>
    );
};