import {Route} from "react-router-dom";
import * as React from "react";
import {AdminLayout} from "./pages/admin/layout";
import {SuperPage} from "./pages/super";
import {ParamListPage} from "./pages/super/param";
import {ParamEditPage} from "./pages/super/param/form";
import {UserListPage} from "./pages/super/user";
import {UserEditPage} from "./pages/super/user/form";
import SideBarItem from "./sidebar_item";
import {BusinessCenter, Dns, HowToReg} from "@mui/icons-material";


const SuperRoutes = () => {
	return (
		<Route path="/app/admin/super" element={<AdminLayout/>}>
			<Route index element={<SuperPage/>}/>
			<Route path="/app/admin/super/param" element={<ParamListPage/>}/>
			<Route path="/app/admin/super/param/:id" element={<ParamEditPage/>}/>
			<Route path="/app/admin/super/param/new" element={<ParamEditPage/>}/>
			<Route path="/app/admin/super/user" element={<UserListPage/>}/>
			<Route path="/app/admin/super/user/:id" element={<UserEditPage/>}/>
			<Route path="/app/admin/super/user/new" element={<UserEditPage/>}/>
		</Route>
	);
}

export default SuperRoutes;

export const SuperMenu = () => {
	return (
		<>
			<SideBarItem
				href={"/app/admin/super/param"}
				label={"Parámetros"}
				icon={<Dns/>}
			/>
			<SideBarItem
				href={"/app/admin/super/user"}
				label={"Usuarios"}
				icon={<HowToReg/>}
			/>
		</>
	)
}