import {
	Alert,
	Box,
	Card,
	CardContent,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme
} from "@mui/material";
import AdminInner from "../../../components/layout/admin_inner";
import {useApiManager} from "../../../components/utils/api";
import {EmpBudgetRestControllerApiFactory, EmployeeBudget, EmployeeBudgets} from "arteco-api-client-ts";
import React, {useEffect, useState} from "react";
import moment from "moment";


export const useBudgets = () => {
	const apiManager = useApiManager();
	const budgetApi = apiManager.factory(EmpBudgetRestControllerApiFactory);
	const [ownBudgets, setOwnBudgets] = useState<EmployeeBudgets | undefined>(undefined);

	useEffect(() => {
		apiManager.execute(budgetApi.getActiveBudgets())
		.then(data => setOwnBudgets(data))
	}, []);

	return ownBudgets;
}

export const EmployeeBudgetPage = () => {
	const budgets = useBudgets();
	const theme = useTheme();
	const [show, setShow] = useState<EmployeeBudget | undefined>(undefined);

	const getDiff = (budget: EmployeeBudget): number => {
		const total = budget.totalBudgetHours || 0;
		const consolidated = budget.consolidatedHours || 0;
		const actual = budget.actualPeriodHours || 0;
		return total - consolidated - actual;
	}

	return (
		<>
			<Grid container spacing={3}>
				<Grid item sm>
					<AdminInner title={"Presupuestos activos"}>
						<Alert color={"info"}>
							Aquí podrás revisar los presupuestos activos que tienes asignados. Necesitarás el
							código de cliente y de presupuesto para indicarlo en las tareas de <b>redmine</b>.
							Recuerda que de no estar indicado en la tarea, se hará una búsqueda ascendente en la
							jerarquía.
						</Alert>
						<TableContainer component={Paper} sx={{mt: 2}}>
							{budgets && budgets.budgets && budgets.budgets.length > 0 ? (
								<>
									<Table size="small">
										<TableHead>
											<TableRow>
												<TableCell sx={{color: "white"}}>Título</TableCell>
												<TableCell sx={{color: "white"}}>Cliente</TableCell>
												<TableCell sx={{color: "white"}} align="right">Totales</TableCell>
												<TableCell sx={{color: "white"}} align="right">Consolidadas</TableCell>
												<TableCell sx={{color: "white"}} align="right">Actuales</TableCell>
												<TableCell sx={{color: "white"}} align="right">Diff</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{budgets?.budgets.map((budget, idx) => (
												<TableRow key={idx}>
													<TableCell
														onMouseEnter={_ => {
															setShow(budget);
														}}
														onMouseLeave={_ => {
															setShow(undefined);
														}}
														sx={{
															color: (budget.acceptation == null || budget.analysis == null)
																? (budget.id == show?.id ? "white" : theme.palette.warning.main)
																: "inherit",
															cursor: "default",
															backgroundColor: budget.id == show?.id ? theme.palette.secondary.dark : "inherit",
														}}>
														{budget.id} - {budget.title}
													</TableCell>
													<TableCell>{budget.customerId} - {budget.customerName}</TableCell>
													<TableCell align="right">{budget.totalBudgetHours}</TableCell>
													<TableCell align="right">{budget.consolidatedHours}</TableCell>
													<TableCell align="right">{budget.actualPeriodHours}</TableCell>
													<TableCell align="right"
															   sx={{color: getDiff(budget) > 0 ? "green" : "red"}}>{getDiff(budget).toFixed(1)}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</>
							) : (
								<Alert color="success" sx={{m: 2}}>
									No tienes presupuestos activos
								</Alert>
							)}
						</TableContainer>
						{budgets && (<>
							<small>Presupuestos de <span style={{color: theme.palette.warning.main}}>color</span>
								&nbsp;indica que falta algún dato en su ficha de la extranet.
								Actualizado el {moment(budgets.tstamp).format("DD/MM HH:mm")}</small>
						</>)}


						<Grid container spacing={3}>
							<Grid item xs={6}>
								<Box sx={{fontSize: "0.8rem", my: 4}}>
									<dl>
										<dt>Consolidadas</dt>
										<dd>Corresponden a las horas del cierre de mes anterior.</dd>

										<dt>Actuales</dt>
										<dd>Son las horas del período actual obtenidas de redmine (cacheadas).</dd>

										<dt>Totales</dt>
										<dd>Son las horas registradas en el presupuesto.</dd>
									</dl>
								</Box>
							</Grid>
							<Grid item xs={6}>
								{show && (
									<Card elevation={6} sx={{mt: 4, borderRadius: 3, maxWidth: 400, p: 2}}>
										<CardContent>
											<Typography variant="h6" fontWeight="bold" gutterBottom>
												{show.title}
											</Typography>
											<Box mt={2}>
												<Typography variant="body2" fontWeight="bold" color="primary">
													Justificación de aceptación
												</Typography>
												<Typography variant="body2"
															color={show.acceptation ? "text.secondary" : theme.palette.warning.main}>
													{show.acceptation || "Pendiente de indicar"}
												</Typography>
											</Box>
											<Box mt={2}>
												<Typography variant="body2" fontWeight="bold" color="primary">
													Ubicación del análisis
												</Typography>
												<Typography variant="body2"
															color={show.analysis ? "text.secondary" : theme.palette.warning.main}>
													{show.analysis || "Pendiente de indicar"}
												</Typography>
											</Box>
										</CardContent>
									</Card>
								)}
							</Grid>
						</Grid>

					</AdminInner>
				</Grid>
			</Grid>
		</>
	);
}