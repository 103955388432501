import React from "react";
import {AdminBackupRestControllerApiFactory, Backup} from "arteco-api-client-ts";
import {FormContent} from "../../../../components/form/form_inputs/form_buttons";
import {FormInput} from "../../../../components/form/form_inputs/form_input";
import FormToggle from "../../../../components/form/form_inputs/form_toggle";
import {types} from "../../../../components/form/types";
import {Button, Grid} from "@mui/material";
import FormSelectButtons from "../../../../components/form/form_inputs/form_select_buttons";
import {useForm, UseFormProps} from "../../../../components/form/form_model";
import {useApiManager} from "../../../../components/utils/api";

export const BackupEditPage = () => {
    const manager = useApiManager();
    const ctrl = manager.factory(AdminBackupRestControllerApiFactory);
    

    const form = useForm({
        fetcher: (id) => ctrl.getBackup(id),
        updater: (bean) => ctrl.saveBackup(bean),
        factory: () => {
            return { } as Backup;
        },
        remover: (id) => ctrl.deleteOrDismissBackup(id),
    } as UseFormProps<Backup>);

    return (
        <>
            <FormContent form={form} title={"Formulario de backups"}>
                <Grid container spacing={3}>
                    <Grid item sm={9}>
                        <FormInput type={"text"} field={"title"} label={"Título"} />
                    </Grid>
                    <Grid item sm={3}>
                        <FormInput field={"last"} type={"text"} label={"Última ejecución"} />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormToggle field={"disabled"} label={"Inactivo"} />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormSelectButtons field={"type"} label={"Tipo"} type={types.backupTypes} />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormSelectButtons
                            field={"frequency"}
                            label={"Frecuencia"}
                            type={types.backupFrequency}
                            multiple
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item sm>
                        <FormInput field={"data"} type={"text"} label={"Datos"} rows={5} />
                    </Grid>
                </Grid>
                {form.getId() &&
                    <Button variant={"outlined"} color={"secondary"}
                            onClick={_ => {
                                if (confirm("Seguro que desea ejecutar la tarea?")) {
                                    ctrl.executeBackup(form.getId() as number);
                                }
                            }}>Ejecutar</Button>
                }
            </FormContent>
        </>
    );
};